@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  display: flex;
  height: px2rem(176);
  width: 100%;
  overflow: hidden;
}

.item {
  @include flex--center;
  width: px2rem(144);
  height: px2rem(48);
  margin-right: getSpacing(3);
}

.imageWrapper {
  height: 100%;
}

.image {
  height: 100%;
  width: auto;
  object-fit: contain;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    height: px2rem(248);
  }

  .item {
    width: px2rem(152);
    height: px2rem(56);
    margin-right: getSpacing(4);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    height: px2rem(256);
  }

  .item {
    width: px2rem(176);
    height: getSpacing(8);
  }
}
