@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  position: relative;
  z-index: getZIndex('navigation');
  display: flex;
  flex-flow: column nowrap;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: getSpacing(3);
  flex: 0 0 auto;
}

.callToActionMedium {
  display: none;
}

.logoMedium {
  display: none;
}

.close {
  display: none;
}

.items {
  background-color: var(--color-background-primary-lightest);
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  text-align: center;
  padding: getSpacing(8);
  overflow: auto;
}

.category {
  display: flex;
  flex-flow: column nowrap;

  &::after {
    content: '';
    display: block;
    width: getSpacing(2);
    height: 1px;
    background: var(--color-background-primary-darkest);
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.item,
.category::after {
  margin-bottom: getSpacing(4);
}

.languages {
  margin: 0 auto getSpacing(4);
}

.isOpen {
  &.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-primary-white);
  }

  .burger {
    display: none;
  }

  .close {
    display: inherit;
  }

  .items {
    display: flex;
  }
}

.disclosure {
  margin-bottom: getSpacing(2);

  .item {
    @include text-misc-button;
    display: flex;
    gap: getSpacing(1);
    text-decoration: none;
    color: inherit;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }

  .chevron.isOpen {
    transform: rotate(180deg);
  }

  .label {
    display: block;
  }

  .panel {
    padding: getSpacing(2) 0;
    margin: 0 auto;
    width: fit-content;
  }

  .heading {
    @include text-body-body-s-bold;
    margin-bottom: getSpacing(1);
  }

  .column {
    margin-bottom: getSpacing(3);
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: getSpacing(1.5);
    margin: 0;
    padding: 0;
  }

  .link {
    @include text-body-body-s-regular;
    display: flex;
    justify-items: center;
    align-items: center;
    gap: getSpacing(1);
    text-align: center;
    white-space: nowrap;

    .icon {
      display: block;
      width: 16px;
    }
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .isOpen .header {
    overflow: hidden;
  }

  .logoSmall {
    display: none;
  }

  .logoMedium {
    display: block;
  }

  .callToActionSmall {
    display: none;
  }

  .callToActionMedium {
    display: block;
    margin-left: auto;
    margin-right: getSpacing(4);
  }

  .category:nth-last-child(2)::after {
    content: none;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .wrapper {
    display: none;
  }
}
