@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include text-heading-display;
  padding: getSpacing(12) 0;
  color: var(--color-text-muted);
  background-color: var(--color-background-primary-white);

  &.dark {
    color: var(--color-text-muted);
    background-color: var(--color-background-primary-darkest);
  }

  &.noPadding {
    padding: 0;
  }
}

.acquisition {
  color: var(--color-text-acquisition-alt);
  background-color: var(--color-background-primary-white);

  &.dark {
    color: var(--color-text-white);
    background-color: var(--color-background-acquisition-dark);
  }
}

.marketplace {
  color: var(--color-text-marketplace-alt);
  background-color: var(--color-background-primary-white);

  &.dark {
    color: var(--color-text-white);
    background-color: var(--color-background-marketplace-dark);
  }
}

.small {
  @include text-heading-heading-s;
  padding: getSpacing(6) 0;
}

.medium {
  @include text-heading-heading-m;
  padding: getSpacing(8) 0;
}
