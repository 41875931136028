@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  @include grid--xs;
  padding-top: getSpacing(12);
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 3 * #{$page__padding--xs});
  height: 100%;
  padding-top: getSpacing(12);
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}

.shape {
  flex: 0 0 auto;
  height: 100%;
  width: auto;
  color: var(--color-background-acquisition-dark);
}

.fill {
  flex: 1 1 auto;
  margin-right: -1px;
  height: 100%;
  background: var(--color-background-acquisition-dark);
}

.content {
  position: relative;
  grid-column: 1 / -1;
  padding: getSpacing(13) 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.heading {
  @include text-heading-heading-l();
  color: var(--color-white);
  margin-bottom: getSpacing(4);
}

.picture {
  display: none;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(12);
  }

  .background {
    width: calc(100% - #{$page__padding--md});
  }

  .content {
    grid-row: 1;
    grid-column: 1 / 6;
  }

  .picture {
    position: relative;
    display: block;
    grid-row: 1;
    grid-column: 6 / -1;
    height: 100%;
    width: calc(100% + #{$page__padding--md});
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(12);
  }

  .background {
    position: relative;
    padding-top: 0;
    grid-row: 1;
    grid-column: 1 / -3;
    width: calc(100% + #{$page__padding--lg});
    margin-left: -1 * $page__padding--lg;
  }

  .content {
    grid-column: 2 / 6;
  }

  .picture {
    grid-column: 7 / -1;
  }

  .image {
    object-fit: contain;
    object-position: center;
  }
}

// ------------------------------------------------------------
//  PAGE MAX WIDTH BREAKPOINT
// ------------------------------------------------------------

@media (min-width: $page__max-width) {
  .section {
    overflow: visible;
  }

  .fill {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      margin-right: -1px;
      width: calc(((100vw - #{$page__max-width}) / 2) + 1px);
      height: 100%;
      background-color: var(--color-background-acquisition-dark);
    }
  }
}
