@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding-top: getSpacing(6);
  padding-bottom: getSpacing(6);
}

.heading,
.paragraph {
  grid-column: 1 / -1;
}

.heading {
  @include text-misc-subheading;
  padding-bottom: getSpacing(4);
  color: var(--color-text-highlight);

  &.isAcquisition,
  &.isMarketplace {
    color: var(--color-text-secondary);
  }
}

.paragraph {
  @include text-heading-heading-s;
  color: var(--color-text-primary);

  &.isAcquisition {
    color: var(--color-text-acquisition);
  }

  &.isMarketplace {
    color: var(--color-text-marketplace);
  }
}

.isCentered {
  text-align: center;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(7);
    padding-bottom: getSpacing(7);
  }

  .heading {
    padding-bottom: getSpacing(5);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(7);
    padding-bottom: getSpacing(7);
  }

  .paragraph {
    grid-column: 1 / 9;
  }

  .isCentered {
    .paragraph {
      grid-column: 1 / -1;
    }
  }
}
