@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}

.heading {
  @include text-heading-heading-m;
  margin-top: getSpacing(4);
}

.description {
  @include text-body-body-m-regular;
  margin-top: getSpacing(2);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: getSpacing(4);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    padding: 0 getSpacing(2);
  }

  .heading {
    margin-top: 0;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    padding: 0 getSpacing(13);
  }
}
