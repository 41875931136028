@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding-left: $page__padding--xs;
  padding-right: $page__padding--xs;
  padding-top: getSpacing(12);
  padding-bottom: getSpacing(12);
}

.buttons {
  position: relative;
  display: inline-flex;
  justify-content: center;
  border: 1px solid var(--color-border-primary);
  margin: 0 auto getSpacing(7);
  border-radius: getRadius('regular');
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color-text-acquisition-alt);
  border-radius: getRadius('regular');
}

.button {
  position: relative;
  padding: getSpacing(1.5) getSpacing(3);
  text-transform: uppercase;
  color: var(--color-text-primary);
  @include text-misc-button();

  &:disabled {
    color: var(--color-text-white);
  }
}

.cards {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0;
}

.card {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: $grid__gap--xs;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-left: $page__padding--md;
    padding-right: $page__padding--md;
  }

  .buttons {
    margin-bottom: getSpacing(8);
  }

  .card {
    width: calc((100% - #{$grid__gap--md}) / 2);

    margin-bottom: $grid__gap--md;

    &:not(:nth-child(2n)) {
      margin-right: $grid__gap--md;
    }
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-left: $page__padding--lg;
    padding-right: $page__padding--lg;
    max-width: $page__max-width;
  }

  .card {
    width: calc((100% - #{$grid__gap--lg} * 2) / 3);

    margin-bottom: $grid__gap--lg;

    &:not(:nth-child(2n)) {
      margin-right: unset;
    }

    &:not(:nth-child(3n)) {
      margin-right: $grid__gap--lg;
    }
  }
}
