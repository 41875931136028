@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

$transition: all 0.3s ease-out;

.wrapper {
  position: relative;
  border-radius: getRadius('regular');
  display: flex;
  width: 100%;
}

.border {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-highlight);
  border-radius: getRadius('regular');
  opacity: 0;
  transition: $transition;
  will-change: transform;
  pointer-events: none;
}

.wrapper:not(:hover) .shadow {
  opacity: 0;
}

.container {
  position: relative;
  padding: getSpacing(2.5);
  border-radius: getRadius('regular');
  background: var(--color-background-primary-white);
  width: 100%;
}

.picture {
  display: block;
  position: relative;
  border-radius: getRadius('x-small');
  padding-top: 63%;
  overflow: hidden;

  will-change: transform;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: getRadius('x-small');
  transition: $transition;
}

.category {
  @include text-body-body-xs-bold;
  background-color: var(--color-background-highlight-light);
  color: var(--color-background-highlight);
  padding: getSpacing(1) getSpacing(3);
  border-radius: getRadius('regular');
  margin-top: getSpacing(3);
  display: inline-block;
}

.heading {
  @include text-body-body-m-bold;
  margin-top: getSpacing(3);
  transition: $transition;
}

@media (hover: hover) {
  .wrapper:hover {
    cursor: pointer;
  }

  .wrapper:hover .border {
    opacity: 1;
  }

  .wrapper:hover .container {
    border-color: var(--color-border-highlight);
  }

  .wrapper:hover .image {
    transform: scale(1.1);
  }

  .wrapper:hover .heading {
    color: var(--color-text-highlight-alt);
  }
}
