@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.scrollingTitle,
.heading,
.subheading,
.paragraphs,
.paragraph,
.buttons {
  grid-column: 1 / -1;
}

.section {
  position: relative;
  padding: getSpacing(14) 0;
  overflow-x: hidden;
}

.scrollingTitle {
  margin-bottom: getSpacing(14);
}

.container {
  @include grid--xs;
}

.heading {
  @include text-misc-subheading;
  color: var(--color-text-highlight);
  margin-bottom: getSpacing(4);
}

.subheading {
  @include text-heading-heading-s;
  margin-bottom: getSpacing(4);
}

.paragraph {
  @include text-body-body-s-regular;

  &:not(:last-child) {
    margin-bottom: getSpacing(4);
  }
}

.button {
  @include width-fit-content();
  margin-top: getSpacing(5);
}

.picture {
  display: none;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-bottom: getSpacing(25);
  }

  .scrollingTitle {
    margin-bottom: getSpacing(7);
  }

  .container {
    @include grid--md;
  }

  .heading {
    margin-bottom: getSpacing(5);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .scrollingTitle,
  .heading,
  .subheading,
  .paragraph {
    grid-column: 1 / 10;
  }

  .section {
    min-height: 100vh;
    padding-bottom: getSpacing(14);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    &.noPaddingTop {
      padding-top: 0;
    }
  }

  .container {
    @include grid--lg;
    padding-top: 0;
    position: relative;
    z-index: 2;
  }

  .heading.isReversed {
    grid-column: 5 / -1;
  }

  .subheading {
    margin-bottom: getSpacing(8);

    &.isReversed {
      grid-column: 5 / 9;
    }
  }

  .paragraphs {
    @include grid--lg;
    padding: 0;

    &.isReversed {
      grid-column: 5 / -1;
    }
  }

  .paragraph {
    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:first-child {
      grid-column: 2 / 6;

      &.isReversed {
        grid-column: 1 / 6;
      }
    }

    &:last-child {
      grid-column: 6 / 10;

      &.isReversed {
        grid-column: 6 / 11;
      }
    }
  }

  .buttons {
    @include grid--lg;
    padding: 0;

    &.isReversed {
      grid-column: 5 / -1;
    }
  }

  .button {
    &:first-child {
      grid-column: 2 / 6;

      &.isReversed {
        grid-column: 1 / 6;
      }
    }

    &:last-child {
      grid-column: 6 / 10;

      &.isReversed {
        grid-column: 6 / 11;
      }
    }

    &.defaultButton {
      grid-column: 2 / 6;
      margin-bottom: getSpacing(16);

      &.isReversed {
        grid-column: 1 / 6;
      }
    }
  }

  .picture {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    height: calc(100% - 2 * #{getSpacing(14)});
    clip-path: circle();
    transform: translate(50%, -50%);

    &.alignTop {
      height: calc(100% - #{getSpacing(14)});
      top: 0;
      transform: translateX(50%);
    }

    &.isReversed {
      left: 0;
      right: unset;
      transform: translate(-50%, -50%);

      &.alignTop {
        transform: translateX(-50%);
      }
    }
  }

  .image {
    height: 100%;
    object-fit: cover;
  }
}
