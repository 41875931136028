@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.article {
  padding: getSpacing(4);
  border-radius: getRadius('card');

  &:not(:last-child) {
    margin-bottom: getSpacing(2);
  }

  &.isActive {
    background-color: var(--color-background-primary-lightest);

    &.isAlternative {
      background-color: var(--color-background-primary-shady);
    }
  }
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.isActive {
    margin-bottom: getSpacing(1.5);
    cursor: auto;
  }
}

.heading {
  @include text-body-body-m-regular;

  &.isAlternative {
    color: var(--color-text-white);
  }

  &.isActive {
    color: var(--color-text-highlight);

    &.isAlternative {
      color: var(--color-text-white);
    }
  }
}

.content {
  opacity: 0;
  transition: all 0.3s ease 0.3s;

  &.isActive {
    opacity: 100%;
  }
}

.paragraph {
  @include text-body-body-s-regular;
  margin: getSpacing(2) 0 getSpacing(3);
  color: var(--color-text-secondary);
  display: none;

  &.isAlternative {
    color: var(--color-text-white);
  }

  &.isActive {
    display: block;
  }
}

.button {
  display: none;
  justify-content: start;
  text-align: left;

  &.isActive {
    display: flex;
  }
}

.icon {
  display: inline-block;
  border-radius: 50%;
  padding: getSpacing(0.75);
  margin-right: getSpacing(2);
  background-color: var(--color-background-highlight-light);

  & path {
    fill: var(--color-white);
  }

  &.isAlternative {
    background-color: var(--color-background-primary-dark);

    &.isActive {
      background-color: var(--color-background-primary-dark);
    }
  }

  &.isActive {
    background-color: var(--color-background-highlight);
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .article {
    padding: getSpacing(4);
  }

  .heading {
    @include text-body-body-l-regular;
  }

  .paragraph {
    margin-bottom: 0;

    &.isActive {
      margin-bottom: getSpacing(4);
    }
  }
}
