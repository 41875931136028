@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;

  @media (min-height: $page__max-height) {
    min-height: unset;
    height: 100vh;
    max-height: $page__max-height;
  }
}

.section {
  position: relative;
  overflow-x: hidden;
  @include grid--xs;
  padding-top: getSpacing(16);
  padding-bottom: getSpacing(16);
}

.background {
  display: none;
}

.shape {
  position: absolute;
  height: 100%;
  width: 130%;
  top: 0;
  left: -30%;
  color: var(--color-background-highlight);
}

.content {
  position: relative;
  grid-column: 1 / -1;
  align-self: center;
  color: var(--color-text-white);
}

.watermark {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-50%);

  &.s {
    display: block;
  }

  &.m,
  &.l {
    display: none;
  }
}

.heading {
  @include text-heading-heading-m;
  margin-bottom: getSpacing(5);
}

.buttons {
  display: none;
}

.testimonials {
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
}

.testimonial {
  display: block;
  width: 100%;
  opacity: 0;
  visibility: hidden;

  & + & {
    margin-left: -100%;
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
  }

  .shape {
    width: 85%;
    left: 15%;
  }

  .content {
    grid-column: 4 / -1;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .watermark {
    &.m {
      display: block;
    }

    &.s,
    &.l {
      display: none;
    }
  }

  .buttons {
    margin: auto 0;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .indexes {
    @include text-body-body-xs-bold;
    color: var(--color-text-highlight-alt);
    margin: getSpacing(2) 0;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .index {
    display: block;
    text-align: center;
  }

  .button {
    padding: getSpacing(3);
    border-radius: 50%;
    background: var(--color-background-primary-white);
    box-shadow: getShadow('default');
    transition: all 0.25s ease-out;

    &:hover {
      box-shadow: getShadow('shrunk');
    }
  }

  .arrow path {
    fill: var(--color-icon-highlight);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
  }

  .shape {
    width: 78%;
    left: 22%;
  }

  .content {
    grid-column: 5 / -1;
  }

  .watermark {
    left: 20%;

    &.l {
      display: block;
    }

    &.s,
    &.m {
      display: none;
    }
    transform: translateX();
  }
}

@media (min-width: $page__max-width) {
  .background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: calc((101vw - #{$page__max-width}) / 2);
    height: 100%;
    background-color: var(--color-background-highlight);
  }
}
