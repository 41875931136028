@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  width: 100%;
  overflow: hidden;
  padding-top: getSpacing(2);
  padding-bottom: getSpacing(2);
  display: flex;
  flex-direction: column;
}

.grid {
  @include grid--xs-v2;
}

.content {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: getSpacing(3);
  margin-bottom: getSpacing(5);
  text-align: center;
}

.heading {
  @include text-misc-subheading;
  color: var(--color-text-highlight);
}

.paragraph {
  @include text-heading-heading-m;
}

.avatar {
  width: px2rem(90);
  height: px2rem(146);

  img {
    display: block;
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
}

.bold {
  display: inline-block;
  padding: getSpacing(0) getSpacing(2);
  line-height: 1.25em;
  border-radius: getRadius('rounded');
  color: var(--color-white);
  background: linear-gradient(66.04deg, #9479F7 0%, #DCDCFF 99.98%);
}

.caption {
  @include text-body-body-s-regular;
  color: var(--color-text-secondary);
}

.images {
  position: relative;
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageswrapper {
  position: relative;
  width: 100%;
  max-width: 592px;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));

  border-radius: 40px;
  border: 1px solid #6BC8EE;
  overflow: hidden;
}

.image {
  position: relative;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: none;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));

  &.isActive {
    display: grid;
  }
}

.imageContainer {
  grid-column: 1/-1;
  grid-row: 1/-1;
  position: relative;
}

.imageContainer > img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top right;
}

.imageAiContainer {
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}

.imageAiContainer > img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top right;
}

.compCtrlWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 2px;
  margin-left: -1px;
  background: var(--anim, linear-gradient(294deg, #9479F7 0%, #8ADCFE 100%));
}

.compCtrl {
  position: absolute;
  height: px2rem(60);
  width: 0;
  top: 50%;
  left: 50%;

  svg {
    width: px2rem(60);
    background-color: var(--color-text-white);
    border-radius: 50%;
    margin-top: -30px;
    margin-left: -30px;

    path {
      transform: scale(0.7);
      transform-origin: 50% 50%;
    }
  }
}

.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: var(--Illu---bleu, linear-gradient(120deg, #221F61 27.31%, #3A367E 77.42%));

  max-width: px2rem(93);

  &.labelWithout {
    left: px2rem(32);
  }

  &.labelWith {
    right: px2rem(32);
  }

  p {
    color: #FFF;
    text-align: center;
    @include text-body-body-xs-bold;
  }
}

.select {
  display: inline-block;
  color: var(--color-text-highlight-alt);

  /* stylelint-disable no-descending-specificity */
  :global(.react-select__control) {
    background: none;
    border-radius: unset;
    border: none;
    display: flex;
    justify-content: flex-start;
    column-gap: px2rem(10);

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__control.react-select__control--is-focused) {
    position: relative;
    border: none;
    box-shadow: none;
    background: var(--text-text-highlight-gradiant);

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--color-background-primary-lightest);
    }
  }

  :global(.react-select__control.react-select__control--menu-is-open) {
    box-shadow: unset;
  }

  :global(.react-select__value-container) {
    position: relative;
    padding: 0 0 0 getSpacing(1);
  }

  :global(.react-select__control:hover .react-select__value-container::after) {
    width: calc(100% - 12px);
  }

  :global(.react-select__single-value) {
    color: inherit;
  }

  :global(.react-select__indicators) {
    position: relative;
  }

  :global(.react-select__indicator) {
    padding: 0 getSpacing(1);
    color: var(--color-text-highlight-alt);

    &:hover {
      color: var(--color-text-highlight-alt);
    }
  }

  :global(.react-select__menu) {
    padding: 0;
    background: var(--color-background-primary-white);
    border-radius: getRadius('x-small');
    box-shadow: 37px 37px 84px rgba(136, 141, 188, 0.16);
    z-index: getZIndex('wizard');
  }

  :global(.react-select__menu-list) {
    padding: 0;
    border-radius: getRadius('x-small');
  }

  :global(.react-select__option) {
    padding: getSpacing(1) getSpacing(2);
    @include text-body-body-s-bold;
    color: var(--color-text-primary);

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__option--is-selected) {
    color: var(--color-text-highlight);
    background: none;
  }

  :global(.react-select__option--is-focused),
  :global(.react-select__option:active) {
    background: var(--color-background-primary-lightest);
  }

  :global(.react-select__input) {
    caret-color: transparent;
  }
  /* stylelint-enable no-descending-specificity */
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }

  .grid {
    @include grid--md-v2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(0);
    padding-bottom: getSpacing(0);

    &.paddings {
      padding-top: getSpacing(6);
      padding-bottom: getSpacing(6);
    }
  }

  .grid {
    @include grid--lg-v2;
  }

  .content {
    grid-column: 1 / 6;
    margin: 0;
    text-align: left;
    align-items: flex-start;
  }

  .images {
    grid-column: 6 / -1;
    justify-content: flex-end;
  }
}
