@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding-top: getSpacing(12);
  padding-bottom: getSpacing(12);
}

.background {
  position: relative;
  grid-column: 1 / -1;
  grid-row: 1;
}

.shape {
  position: absolute;
  top: getSpacing(-5);
  left: -1 * $page__padding--xs;
  width: 100%;
  height: calc(100% + #{getSpacing(10)});
  color: var(--color-border-acquisition-dark);
}

.thumbnail {
  position: relative;
  overflow: hidden;
  grid-column: 1 / -1;
  grid-row: 1;
  border-radius: getRadius('small');
  padding-top: 56.25%;
  box-shadow: 0 24px 44px 20px rgba(16, 14, 58, 0.2);
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $gradient--dark;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: getRadius('small');
  transition: all 0.25s ease-out;

  .thumbnail:hover & {
    transform: scale(1.05);
  }
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: getSpacing(8);
  height: getSpacing(8);
  border: 2px solid var(--color-background-primary-white);
  border-radius: 50%;
  background: rgba(227, 227, 238, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  transition: all 0.25s ease-out;
}

.triangle {
  position: relative;
  left: 3px;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(18);
    padding-bottom: getSpacing(18);
  }

  .shape {
    left: -1 * $page__padding--md;
    top: getSpacing(-7);
    height: calc(100% + #{getSpacing(14)});
  }

  .button {
    width: getSpacing(10);
    height: getSpacing(10);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(24);
    padding-bottom: getSpacing(24);
  }

  .background {
    position: relative;
    grid-column: 1 / -2;
    grid-row: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -49vw;
      height: 100%;
      width: 50vw;
      background-color: var(--color-border-acquisition-dark);
    }
  }

  .shape {
    left: -1 * $page__padding--lg;
  }

  .shape,
  .background::after {
    top: getSpacing(-14);
    height: calc(100% + #{getSpacing(28)});
  }

  .thumbnail {
    grid-column: 2 / -2;
  }
}
