@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.card {
  display: block;
  position: relative;
  margin: 0;
  padding: 78% 0 0 0;
  border-radius: getRadius('regular');
  overflow: hidden;
}

.image,
.gradient,
.heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: cover;
  object-position: center;
}

.gradient {
  background: $gradient--dark;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-white);
  text-align: center;
  @include text-heading-heading-m;
}
