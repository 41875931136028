@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container.isAlternative {
  background-color: var(--color-background-primary-darkest);
}

.section {
  @include grid--xs;
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(8);
  overflow: hidden;

  &.isAlternative {
    color: var(--color-text-primary-alt);
    background-color: var(--color-background-primary-darkest);
  }
}

.heading,
.items {
  grid-column: 1 / -1;
}

.items {
  align-self: center;
}

.heading {
  @include text-heading-heading-m;
  grid-column: 1 / -1;
  margin-bottom: getSpacing(4);
}

.picture {
  display: none;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(10);
    padding-bottom: getSpacing(10);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(12);
    padding-bottom: getSpacing(12);
  }

  .heading {
    grid-column: 1 / -1;
  }

  .items {
    grid-column: 1 / 7;
  }

  .picture {
    grid-column: 7 / -1;
    position: relative;
    width: 100%;
    padding-top: 100%;
    align-self: center;
    @include flex--center;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transform: translateY(10%);
    transition: all 0.5s ease;

    &.isActive {
      display: block;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
