@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.button {
  padding: getSpacing(3);
  border-radius: 50%;
  background: var(--color-background-primary-white);
  box-shadow: getShadow('default');
  transition: all 0.25s ease-out;

  &:hover {
    box-shadow: getShadow('shrunk');
  }

  &:disabled {
    opacity: 0.5;
  }
}

.arrow path {
  fill: var(--color-icon-highlight);
}
