@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  position: relative;
  background: var(--color-background-primary-darkest);
}

.header,
.cards {
  @include grid--xs;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
}

.heading,
.subheading {
  text-align: center;
  grid-column: 1 / -1;
  transition: opacity 1s ease-out;
}

.subheading {
  @include text-misc-subheading();
  @include text-gradient();
  margin-top: auto;
}

.heading {
  margin-top: getSpacing(2);
  color: var(--color-text-white);
  margin-bottom: auto;

  &.noSubheading {
    margin-top: auto;
  }
}

.header.isTransparent {
  & .heading,
  & .subheading {
    opacity: 0.3;
  }
}

.card {
  position: relative;
  grid-column: 1 / -1;
  margin-bottom: getSpacing(4);
}

.spacer {
  height: 100vh;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .header,
  .cards {
    @include grid--md;
  }

  .card {
    grid-column: var(--grid-column-start-md) / span 3;
    transform: var(--translate-y);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .header,
  .cards {
    @include grid--lg;
  }

  .subheading,
  .heading {
    grid-column: 3 / -3;
  }

  .heading {
    @include text-heading-heading-xl;
  }

  .card {
    grid-column: var(--grid-column-start-lg) / span 3;
  }
}
