@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  background-color: var(--color-background-primary-darkest);
}

.section {
  @include grid--xs;
  overflow: hidden;
  padding-top: getSpacing(5);
  padding-bottom: getSpacing(5);
  color: var(--color-text-white);
  align-content: start;
}

.headingContainer,
.content,
.imageContainer {
  grid-column: 1 / -1;
}

.headingContainer {
  position: relative;
  z-index: 1;
}

.heading {
  @include text-heading-heading-l();

  & b {
    font-weight: normal;
    color: var(--color-text-acquisition-alt);
    @include text-gradient();
  }
}

.line3D {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 125%);
}

.content {
  position: relative;
  z-index: 1;
  margin-top: getSpacing(4);
}

.paragraph {
  @include text-body-body-s-regular();
}

$badge_size: getSpacing(16);

.imageContainer {
  position: relative;
  margin-top: getSpacing(4);
  padding-top: calc(100% + 2 * #{$badge_size});
}

.image {
  position: absolute;
  top: $badge_size / 2;
  left: 0;
  clip-path: circle();
  object-fit: cover;
  width: 120%;
  max-width: unset;
}

.sphere3D {
  position: absolute;
  bottom: 0;
  left: 0;
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  height: $badge_size;
  width: $badge_size;
  background-color: var(--color-background-primary-white);
  border-radius: 50%;
}

.logo {
  padding: getSpacing(3);
  height: 100%;
  width: 100%;
  object-fit: contain;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }

  .line3D {
    transform: translate(-75%, 100%);
  }

  .content {
    grid-column: 1 / 6;
  }

  .imageContainer {
    grid-column: 3 / -1;
    margin-top: getSpacing(10);
    padding-top: 100%;
  }

  .image {
    top: 0;
    width: 100%;
    height: 100%;
  }

  .sphere3D {
    left: -35%;
  }

  .badge {
    height: getSpacing(22);
    width: getSpacing(22);
    left: -10%;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    grid-template-rows: auto 1fr;
    padding-top: $page__nav-height--lg;
    padding-bottom: getSpacing(8);
    position: relative;
  }

  .headingContainer {
    grid-column: 2 / span 9;
    grid-row: 1;
  }

  .line3D {
    top: unset;
    bottom: 0;
    transform: translate(100%, 15%);
  }

  .content {
    grid-column: 2 / span 5;
    grid-row: 2;
  }

  .imageContainer {
    grid-column: span 4 /-1;
    grid-row: 2;
    margin-top: getSpacing(4);
  }

  .badge {
    top: 0;
    left: 0;
    transform: translateX(-50%);
    height: getSpacing(18);
    width: getSpacing(18);
  }

  .sphere3D {
    left: 0;
    transform: translateX(-125%);
  }
}
