@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  position: relative;
  background-color: var(--color-background-primary-lightest);

  &.acquisition,
  &.marketplace {
    color: var(--color-text-white);
  }

  &.acquisition {
    background-color: var(--color-background-acquisition);
  }

  &.marketplace {
    background-color: var(--color-background-marketplace);
  }
}

.section {
  @include grid--xs;
  position: relative;
  padding-top: getSpacing(4);
  padding-bottom: getSpacing(10);
}

.article {
  grid-column: 1 / -1;
  position: relative;
  z-index: 1;
  align-self: center;
}

.heading,
.headingPrimary {
  @include text-heading-heading-l-mobile;
  margin-bottom: getSpacing(4);
  color: var(--text-color-white);

  & b {
    font-weight: normal;
  }
}

.paragraph {
  @include text-body-body-s-regular;
  margin-bottom: getSpacing(4);
  width: 80%;
}

.marquee {
  margin: getSpacing(6) 0;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #F7F6FB 0%, rgba(247, 246, 251, 0) 10%, rgba(255, 255, 255, 0) 90%, #FFF 100%);
  }
}

.logo {
  padding: 0 getSpacing(3);
}

.logoImage {
  height: 40px;
  width: auto;
}

.image,
.imageClipped {
  display: none;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.bgCorner {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  stroke: var(--color-white);

  &.acquisition {
    fill: var(--color-background-acquisition-light);
  }

  &.marketplace {
    fill: var(--color-background-marketplace-light);
  }
}

.bgCircleWrapper {
  display: none;
}

.roller {
  position: relative;
  z-index: -1;
}

.placeholder {
  display: inline-block;
  visibility: hidden;
}

.item {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.headingPrimary b,
.headingPrimary b .roller,
.headingPrimary b .item {
  color: var(--color-text-highlight-alt);
  background: var(--text-text-highlight-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(10);
  }

  .heading,
  .headingPrimary {
    @include text-heading-heading-l;
  }

  .buttons {
    display: flex;
    gap: getSpacing(4);
  }

  .bgCorner {
    width: 50%;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: $page__nav-height--lg;
    position: relative;
  }

  .article {
    grid-column: 1 / 7;
    grid-row: 1;
    padding-top: getSpacing(10);
    padding-bottom: getSpacing(10);
  }

  .marquee::after {
    background: linear-gradient(90deg, #F7F6FB 0%, rgba(247, 246, 251, 0) 15%, rgba(247, 246, 251, 0) 85%, #F7F6FB 100%);
  }

  .bgCorner {
    padding-top: $page__nav-height--lg;
  }

  .bgCircleWrapper {
    @include grid--lg;
    height: 100%;
    padding-top: $page__nav-height--lg;
    position: absolute;
    left: 0;
    width: 100%;
    max-width: none;
  }

  .bgCircleContainer {
    overflow: hidden;
    grid-column: 8 / -1;
    height: 100%;
    width: calc(100% + #{$page__padding--lg});
    display: flex;
  }

  .bgCircle {
    flex: 0 0 auto;
    height: 100%;
    width: auto;

    &.acquisition {
      fill: var(--color-background-acquisition-light);
    }

    &.marketplace {
      fill: var(--color-background-marketplace-light);
    }
  }

  .bgCircleFill {
    flex: 1 1 auto;

    &.acquisition {
      background-color: var(--color-background-acquisition-light);
    }

    &.marketplace {
      background-color: var(--color-background-marketplace-light);
    }
  }

  .clipPath {
    flex: 0 0 auto;
    height: 0;
    width: 0;
  }

  .clipContainer {
    position: relative;
    height: 100%;
    align-self: center;
    margin-left: auto;
    justify-self: flex-end;
    aspect-ratio: 1;
  }

  .imageClipped {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    clip-path: url(#HeroSimpleClipPath);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .image {
    position: relative;
    display: block;
    grid-column: 7 / -1;
    grid-row: 1;
    align-self: center;
    width: calc(100% + #{$page__padding--lg});
    margin-right: -1 * $page__padding--lg;
    aspect-ratio: 1;
    object-fit: contain;
  }
}
