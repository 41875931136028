@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  padding-top: getSpacing(5);
  padding-bottom: getSpacing(5);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(4);

  &.isBoxed {
    padding-bottom: getSpacing(1);
    gap: 0;
  }
}

.grid {
  @include grid--xs;
}

.heading {
  grid-column: 1 / -1;
  @include text-heading-heading-s;
  color: var(--color-text-primary);
  text-align: center;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.innermarquee {
  display: flex;

  &.isBoxed {
    padding-top: getSpacing(4);
    padding-bottom: getSpacing(4);
  }
}

.item {
  display: flex;
  width: 144px;
  margin-right: 32px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.isBoxed {
    justify-content: center;
    align-items: center;
    height: 64px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0 2.964px 17.782px 0 rgba(80, 103, 165, 0.08);

    img {
      width: auto;
    }
  }
}

.linkwrapper {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;

  &.isBoxed {
    padding-bottom: getSpacing(4);
  }
}

.link {
  @include text-misc-button;
  color: var(--color-action-primary-hover);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .grid {
    @include grid--md;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .grid {
    @include grid--lg;
  }
}
