@use '@/styles/utils' as *;

.shadow {
  height: getSpacing(4);
}

.container {
  position: relative;
  display: inline-block;

  .button {
    position: relative;
    display: inline-block;
    @include text-misc-button();
    text-decoration: none;
    text-align: center;
    padding: getSpacing(2) getSpacing(4);
    border-radius: getRadius('rounded');
    z-index: 2;
    color: var(--color-white);
    background: var(--color-navy-900);
    transition: all 0.3s ease-out;
    cursor: pointer;

    &.buttonSmall {
      padding: getSpacing(1.5) getSpacing(3);
    }

    &:hover {
      background: var(--color-purple-700);
      filter: brightness(1.05) contrast(1.05);
      transform: scale(0.95);
    }

    &:disabled {
      background: var(--color-navy-300);
      cursor: default;
    }
  }

  &:hover .shadow {
    transform: translateY(getSpacing(-3));
    filter: blur(10px) brightness(0.95);
  }
}

.button.isSecondary,
.button.isSecondary:hover {
  background: var(--color-background-primary-white);
  color: var(--color-text-primary);
}

.marketplace:hover {
  color: var(--color-text-white);
  background-color: var(--color-background-marketplace-dark);
}

.acquisition:hover {
  color: var(--color-text-white);
  background-color: var(--color-background-acquisition-dark);
}
