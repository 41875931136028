@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

@mixin draggable-cards-list__padding() {
  padding-top: getSpacing(6);
  padding-bottom: getSpacing(6);
}

.section {
  @include draggable-cards-list__padding;
  background-color: var(--color-background-primary-lightest);
  overflow: hidden;
}

.heading {
  @include text-heading-heading-s;
  text-align: center;
  color: var(--color-text-acquisition);
  margin: getSpacing(4) 0;
}

.container {
  @include grid--xs;
  position: relative;

  &.hasHeading {
    padding-top: 0;
  }
}

.cards {
  grid-column: 1 / -1;
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  overflow: visible;
  transition: transform 0.1s ease-out;
  cursor: grab;

  &.grabbing {
    cursor: grabbing;
  }

  :global(.items-1) & {
    width: 100%;
  }
}

.card {
  position: relative;
  flex: 0 0 100%;
  display: flex;
  margin-left: $grid__gap--xs;

  &:first-child {
    margin-left: 0;
  }
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed var(--color-border-primary);
  }
}

.arrowSVG {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);

  &:nth-child(2) {
    transform: translate(#{getSpacing(5)}, -50%);
  }

  &.isRight {
    left: unset;
    right: 10%;

    &:nth-child(4) {
      transform: translate(#{getSpacing(5) * -1}, -50%);
    }
  }
}

.arrowPath {
  fill: none;
  stroke: var(--color-border-primary);
  stroke-dasharray: 1;
}

.draggingZone {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
    @include draggable-cards-list__padding;
  }

  .cards {
    grid-column: 1 / span 4;

    :global(.items-1) & {
      grid-column: 3 / span 4;
      width: 100%;
    }

    :global(.items-2) & {
      width: 100%;
    }
  }

  .card {
    margin-left: $grid__gap--md;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
    @include draggable-cards-list__padding;
  }

  .cards {
    position: relative;
    grid-column: 1 / span 3;
    width: 100%;

    :global(.items-1) & {
      width: 100%;
      grid-column: 7 / span 3;
      left: calc(-50% - #{$grid__gap--lg});
    }

    :global(.items-2) & {
      width: 100%;
      grid-column: 4 / span 3;
    }

    :global(.items-3) & {
      width: 100%;
      grid-column: 3 / span 3;
      left: calc(-2 * #{$grid__gap--lg});
    }
  }

  .card {
    margin-left: $grid__gap--lg;
  }
}
