@use '@/styles/utils' as *;

.withLoader {
  position: relative;
  overflow: hidden;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-highlight);
  transform: translateY(101%);
  z-index: 2;
}

.withLoader .image {
  position: relative;
  opacity: 0;
}
