@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  @include grid--xs;
  background-color: var(--color-background-primary-darkest);
  color: var(--color-text-white);
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(8);

  &.isLight {
    background-color: var(--color-background-primary-lightest);
    color: var(--color-text-primary);
  }
}

.item {
  grid-column: 1 / -1;
  justify-content: flex-start;
  padding: getSpacing(8) 0;
}

.picture {
  position: relative;
  padding-top: 100%;
  background-color: var(--color-navy-500);
  margin-bottom: getSpacing(5);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.dots {
  position: absolute;
  top: 0;
  left: getSpacing(4);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
  }

  .item {
    grid-column: 2 / -2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    display: none;
  }
}
