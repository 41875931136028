@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding: 0;
  @include text-body-body-s-regular;
}

.paragraphs,
.picture {
  grid-column: 1 / -1;
}

.paragraphs {
  @include grid--xs;
  padding: getSpacing(12) $page__padding--xs;

  :first-child {
    margin-bottom: getSpacing(4);
  }
}

.paragraph {
  grid-column: 1 / -1;
}

.image {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding: 0;
  }

  .paragraphs {
    @include grid--md;
    padding: getSpacing(12) $page__padding--md;

    :first-child {
      margin-bottom: 0;
      grid-column: 1 / 5;
    }

    :last-child {
      grid-column: 5 / -1;
    }
  }

  .image {
    aspect-ratio: 768 / 360;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding: 0;
    max-width: none;
  }

  .paragraphs {
    @include grid--lg;
    padding: getSpacing(12) $page__padding--lg;

    :first-child {
      grid-column: 1 / 6;
    }

    :last-child {
      grid-column: 6 / 11;
    }
  }

  .image {
    aspect-ratio: 1440 / 360;
  }
}
