@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.heading {
  @include text-heading-heading-xs;
  margin-bottom: getSpacing(4);

  &.isLarge {
    @include text-heading-heading-s;
  }
}

.cards {
  display: grid;
  gap: getSpacing(4);
  grid-template-columns: 1fr;
  grid-auto-flow: row dense;
}

.channelSelected {
  grid-column: 1 / -1;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .cards {
    grid-template-columns: repeat(4, 1fr);

    &.isLarge {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
