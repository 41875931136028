@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  overflow: hidden;
  padding-top: getSpacing(5);
  padding-bottom: getSpacing(5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shapeContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .shape {
    display: none;
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    width: auto;
  }

  .rectAilice {
    position: absolute;
    bottom: 0;
    height: 216px;
    background: linear-gradient(256deg, #6BC8EE -0.2%, #D9D3FF 81.89%);
    left: 15%;
    width: 85%;
    border-bottom-left-radius: 216px;
  }
}

.isFlipped {
  .shape {
    left: 0;
  }
}

.container {
  @include grid--xs-v2;
}

.picture,
.content {
  position: relative;
  grid-column: 1 / -1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: getSpacing(2) 0;
}

.heading,
.headingPrimary {
  @include text-heading-heading-m;
  text-align: center;
  margin-bottom: getSpacing(4);
  color: var(--text-color-white);

  & b {
    font-weight: normal;
  }
}

.roller {
  position: relative;
  z-index: -1;
}

.placeholder {
  display: inline-block;
  visibility: hidden;
}

.item {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.headingPrimary b,
.headingPrimary b .roller,
.headingPrimary b .item {
  color: var(--color-text-highlight-alt);
  background: var(--text-text-highlight-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

.paragraph {
  @include text-body-body-s-regular;
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: getSpacing(1);
}

.picturecontainer {
  width: calc(100% - 32px);
}

.picture {
  margin-top: getSpacing(6);
  display: flex;
  justify-content: center;
}

.imagewrapper {
  position: relative;
  width: 100%;
  max-width: 720px;
}

.imageinnerwrapper {
  width: 100%;
  padding-top: 62.5%;
  height: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.darkTheme {
  background-color: var(--color-background-primary-darkest);

  .heading,
  .headingPrimary,
  .paragraph {
    color: white;
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md-v2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    gap: getSpacing(7);
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
  }

  .shapeContainer {
    position: relative;
    grid-column: 1/-1;
    grid-row: 1/-1;

    .shape {
      display: block;
    }

    .rectAilice {
      display: none;
    }
  }

  .container {
    grid-column: 1/-1;
    grid-row: 1/-1;
    @include grid--lg-v2;
    grid-auto-flow: dense;
  }

  .content {
    grid-column: 1 / span 5;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .heading,
  .paragraph {
    text-align: left;
  }

  .button {
    justify-content: flex-start;
  }

  .picturecontainer {
    position: relative;
    grid-column: 1/-1;
    grid-row: 1/-1;
  }

  .picture {
    width: 70%;
    margin-left: 60%;
    padding: 88px 0;
    margin-top: 0;
    justify-content: flex-start;
  }

  .isFlipped {
    .content {
      grid-column: 7 / span 5;
    }

    .picture {
      margin-left: -33%;
      justify-content: flex-end;
    }
  }
}
