@use '@/styles/utils' as *;

.button {
  @include flex--center;
  flex-direction: row;
  width: 100%;
  @include text-misc-button();
  text-decoration: none;
  text-align: center;
  color: var(--color-text-secondary);
  cursor: pointer;

  .icon {
    @include flex--center;
    width: getSpacing(3);
    height: getSpacing(3);
    border-radius: getRadius('rounded');
    margin-left: getSpacing(1.5);
    background-color: var(--color-background-primary-light);
  }

  .arrow path {
    fill: var(--color-text-secondary);
  }

  &.marketplace {
    color: var(--color-text-acquisition);

    .icon {
      background-color: var(--color-border-acquisition-light);
    }

    .arrow path {
      fill: var(--color-text-acquisition);
    }
  }

  &.acquisition {
    color: var(--color-text-marketplace);

    .icon {
      background-color: var(--color-border-marketplace-light);
    }

    .arrow path {
      fill: var(--color-text-marketplace);
    }
  }

  &.isDark {
    color: var(--color-text-primary-alt);
  }
}
