@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.card {
  padding: getSpacing(6);
  justify-content: space-between;
}

.cardSmall {
  padding: getSpacing(4);
  justify-content: flex-start;
}

.card,
.cardSmall {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  transition: border 0.3s ease;
  border-radius: getRadius('card');
  background-color: var(--color-text-white);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-border-highlight);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 37px 37px 84px rgba(136, 141, 188, 0.16);
    border-radius: getRadius('card');
  }
}

.logoLarge,
.logoSmall {
  height: getSpacing(8);
  width: getSpacing(16);
  display: flex;
  align-items: center;
}

.logoLarge {
  margin-bottom: getSpacing(4);
}

.logoSmall {
  margin-bottom: getSpacing(2);
}

.logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left;
}

.heading {
  @include text-body-body-l-regular;
  margin-bottom: getSpacing(8);
}

.headingSmall {
  @include text-body-body-s-regular;
}

.countries {
  padding-top: getSpacing(4);
  border-top: 1px solid var(--color-border-primary-light);
}

.country {
  margin-right: getSpacing(2);
}
