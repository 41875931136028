@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(12);
  color: var(--color-text-primary);
  background: var(--color-background-primary-lightest);
}

.header,
.heading,
.countries,
.country,
.paragraph,
.list,
.checkmark,
.buttons {
  grid-column: 1 / -1;
}

.header,
.countries,
.paragraph,
.list,
.buttonSecondary {
  margin-bottom: getSpacing(4);
}

.header {
  @include flex--center;
  flex-direction: row;
  justify-content: space-between;
}

.closeButton {
  align-self: flex-start;
  cursor: pointer;
  margin-left: getSpacing(2);
}

.logoImage {
  height: getSpacing(8);
  width: auto;
}

.heading {
  @include text-misc-subheading;
  margin-bottom: getSpacing(2);
  padding-top: getSpacing(1);
}

.country {
  margin-right: getSpacing(1);
}

.listItem {
  @include flex--center;
  flex-direction: row;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: getSpacing(2);
  }
}

.checkmark {
  margin-right: getSpacing(2);
}

.buttonSecondary,
.buttonTertiary {
  @include width-fit-content();
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(12);
  }

  .heading {
    grid-column: 1 / 5;
    margin-bottom: getSpacing(4);
  }

  .countries {
    grid-column: 5 / -1;
    justify-self: end;
    align-self: end;
  }

  .buttons {
    @include flex--center;
    flex-direction: row;
    justify-self: flex-start;
  }

  .buttonSecondary {
    margin-bottom: 0;
    margin-right: getSpacing(4);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(12);
    padding-bottom: getSpacing(12);
  }

  .header,
  .heading,
  .countries,
  .country,
  .paragraph,
  .list,
  .checkmark,
  .buttons {
    grid-column: 2 / 12;
  }

  .heading {
    grid-column: 2 / 7;
  }

  .countries {
    grid-column: 7 / 12;
  }
}
