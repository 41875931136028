@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  background-color: var(--color-background-primary-darkest);
}

.container {
  @include grid--xs;
  @include text-body-body-s-regular;
  color: var(--color-text-primary-alt);
  padding-top: getSpacing(6);
  padding-bottom: getSpacing(8);
}

.addresses {
  grid-column: 1 / -1;
}

.address:not(:first-child) {
  margin-top: getSpacing(4);
}

.logo {
  display: flex;
  justify-content: flex-start;
}

.items {
  grid-column: 1 / -1;
  margin-top: getSpacing(4);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.item {
  flex: 0 0 auto;
  width: calc(50% - (#{$grid__gap--xs}) / 2);
  padding: getSpacing(1.5) 0;
}

.divider {
  grid-column: 1 / -1;
  width: 100%;
  margin: getSpacing(4) 0;
  border: 1px solid var(--color-background-primary-lightest);
  opacity: 0.2;
}

.footnote {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
}

.footnoteItem {
  @include text-body-body-xs-bold;
}

.copyright {
  grid-column: 2 / span 2;
  margin-top: getSpacing(4);
  text-align: center;
  @include text-body-body-xs-regular;

  .footnote & {
    display: none;
  }
}

.languages {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: getSpacing(4);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(12);
  }

  .addresses {
    grid-column: 1 / span 3;
  }

  .items {
    grid-column: 5 / -1;
    grid-row: 1 / span 2;
    margin-top: -1 * getSpacing(2);
    margin-bottom: -1 * getSpacing(2);
  }

  .item {
    width: calc(50% - (#{$grid__gap--md} / 2));
  }

  .divider {
    margin: getSpacing(6) 0;
  }

  .footnote {
    grid-column: 1 / span 6;
    padding: getSpacing(1) 0;
    justify-content: flex-start;
  }

  .copyright {
    display: none;

    .footnote & {
      display: block;
      margin-top: 0;
    }
  }

  .footnoteItem,
  .copyright { /* stylelint-disable-line */
    &:not(:last-child) {
      margin: 0 getSpacing(2) 0 0;
    }
  }

  .languages {
    grid-column: span 2 / -1;
    margin-top: 0;
    justify-content: flex-end;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
    padding-top: getSpacing(12);
    padding-bottom: getSpacing(16);
  }

  .addresses {
    grid-column: 1 / span 6;
    display: flex;
    flex-flow: wrap;
  }

  .logo {
    flex: 0 0 100%;
  }

  .address {
    margin-top: getSpacing(4);

    &:not(:last-child) {
      margin-right: $grid__gap--lg;
    }
  }

  .items {
    grid-column: 7 / -1;
  }

  .item {
    width: calc(33% - ((#{$grid__gap--md} * 2) / 3));

    &:last-child:nth-child(3n + 2) {
      margin-left: $grid__gap--md;
      margin-right: auto;
    }
  }

  .divider {
    margin: getSpacing(8) 0;
  }

  .footnote {
    grid-column: 1 / span 10;
    padding: 0;
  }

  .languages {
    grid-column: span 2 / -1;
    margin-top: 0;
    justify-content: flex-end;
  }
}
