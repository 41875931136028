@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.shadow {
  display: none;
}

.wrapper {
  position: relative;
  display: block;
  width: 100%;

  &:not(:hover) .shadow {
    opacity: 0;
  }

  &.fillHeight {
    height: 100%;
  }
}

.card {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: getSpacing(6) getSpacing(4);
  background-color: var(--color-background-primary-white);
  border-radius: getRadius('regular');

  &.withShadow {
    box-shadow: 37px 37px 84px rgba(33, 30, 95, 0.1);
  }

  .fillHeight & {
    height: 100%;
  }
}

.image {
  margin: 0 auto;
  width: getSpacing(18);
  aspect-ratio: 1;
}

.title {
  @include text-body-body-m-bold;
  text-align: center;
  margin-top: getSpacing(2);
}

.paragraph {
  margin-top: getSpacing(2);
  text-align: center;

  &.withTitle {
    @include text-body-body-s-regular;
  }
}

.linkWrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.link {
  margin-top: getSpacing(3);
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .shadow {
    display: initial;
  }
}
