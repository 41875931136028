@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  padding-top: getSpacing(9);
  padding-bottom: getSpacing(9);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(8);
}

.heading {
  grid-column: 1 / -1;
  text-align: center;
  @include text-heading-heading-s;
  color: var(--color-text-primary);
}

.container {
  @include grid--xs-v2;
}

.wrapper {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: getSpacing(4) 0;
  align-items: center;
}

.card {
  width: 100%;
  max-width: 380px;
  padding: getSpacing(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: getSpacing(1);
}

.titlewrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 12px;
}

.title {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 133.333% */
}

.unit {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 200% */
}

.description {
  font-family: 'Montserrat-Book', sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  line-height: 28px; /* 155.556% */
  text-align: center;

  b,
  strong {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: 700;
  }
}

.logo {
  width: auto;
  height: px2rem(72);
  margin-top: getSpacing(3);

  img {
    height: 100%;
    width: auto;
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-top: getSpacing(7);
    padding-bottom: getSpacing(7);
  }

  .container {
    @include grid--md-v2;
  }

  .wrapper {
    align-items: flex-start;
    flex-direction: row;
    gap: 0 getSpacing(4);
  }

  .card {
    width: 33.33%;
    align-items: flex-start;
  }

  .description {
    text-align: left;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(5);
    padding-bottom: getSpacing(5);
  }

  .container {
    @include grid--lg-v2;
  }
}
