@use '@/styles/utils' as *;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  @include text-misc-button();
  text-decoration: none;
  text-align: center;
  padding: getSpacing(1.5) getSpacing(3);
  color: var(--color-text-highlight);
  background: var(--color-background-highlight-light);
  border-radius: getRadius('rounded');
  cursor: pointer;

  .icon {
    margin-right: getSpacing(1.5);

    & path {
      fill: var(--color-text-highlight);
    }
  }

  &.rightIcon {
    flex-direction: row-reverse;

    .icon {
      margin-right: 0;
      margin-left: getSpacing(1.5);
    }
  }

  &:hover {
    color: var(--color-text-white);
    background: var(--gradient-system-purple-tl);

    .icon path {
      fill: var(--color-text-white);
    }
  }

  &.marketplace {
    color: var(--color-text-marketplace);
    background: var(--color-action-marketplace);

    .icon path {
      fill: var(--color-text-marketplace);
    }

    &:hover {
      color: var(--color-text-white);
      background: var(--gradient-system-blue-tl);

      .icon path {
        fill: var(--color-text-white);
      }
    }
  }
}
