@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  display: none;
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    display: block;
    position: relative;
    width: getSpacing(2);
    height: getSpacing(2);
    padding: getSpacing(1);
    margin-bottom: getSpacing(2);
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: getSpacing(1);
    height: getSpacing(1);
    border-radius: 50%;
    background: var(--color-background-primary-dark);

    .isLight & {
      background: var(--color-background-primary-light);
    }
  }

  .isActive {
    &.container {
      border-color: var(--color-background-primary-white);

      .isLight & {
        border-color: var(--color-background-primary-dark);
      }
    }

    & .dot {
      background-color: var(--color-background-primary-white);

      .isLight & {
        background-color: var(--color-background-primary-dark);
      }
    }
  }
}
