@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  position: relative;
  padding-top: getSpacing(5);
  padding-bottom: getSpacing(5);
  overflow: hidden;
}

.content,
.cards {
  grid-column: 1 / -1;
}

.logos {
  display: none;
}

.heading {
  @include text-heading-heading-l();
}

.paragraph {
  margin-top: getSpacing(4);
}

.button {
  margin-top: getSpacing(8);
}

.circles {
  position: sticky;
  top: 0;
  grid-column: 1 / -1;
  grid-row: 2;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: getSpacing(12);
}

.circlesSvg {
  flex: 0 0 auto;
}

.cards {
  margin-top: getSpacing(16);
  grid-row: 2;
}

.card:not(:first-child) {
  margin-top: getSpacing(4);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    flex: 0 0 auto;
    width: calc(50% - #{$grid__gap--md} / 2);

    &:not(:first-child) {
      margin: 0;
    }

    &:nth-child(1n + 3) {
      margin-top: getSpacing(4);
    }
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(14);
    padding-bottom: getSpacing(14);
    overflow: initial;
  }

  .content {
    position: sticky;
    top: getSpacing(14);
    grid-column: 1 / span 5;
    grid-row: 1;
    align-self: start;
  }

  .logosContainer {
    white-space: nowrap;
  }

  .logos {
    display: inline-flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;

    &:not(:first-child) {
      margin-left: -100%;
    }

    &.isActive {
      opacity: 1;
    }
  }

  .logoWrapper {
    margin-top: getSpacing(5);

    :not(:nth-child(4n + 4)) {
      margin-right: getSpacing(4);
    }
  }

  .logo {
    height: getSpacing(8);
    width: auto;
  }

  .circles {
    top: getSpacing(12) + getSpacing(14);
    grid-column: 7 / -1;
    grid-row: 1;
    align-self: start;
  }

  .cards {
    grid-column: 7 / -1;
    grid-row: 1;
    margin-top: 0;
  }
}
