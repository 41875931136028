@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(8);
  background-color: white;
}

.container {
  position: relative;
  @include grid--xs-v2;
}

.textcontent,
.formcontent {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textcontent {
  gap: getSpacing(4) 0;
  text-align: center;
}

.heading {
  @include text-heading-heading-m;
  color: var(--color-navy-100);

  & b {
    @include text-gradient;
  }
}

.subtitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  @include text-gradient;
  // @include text-body-body-xs-regular;
  // text-transform: uppercase;
  // color: var(--color-border-primary-lightest);
}

.testimonialAuthor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(2);
  margin-top: getSpacing(1);
}

.authorPP {
  position: relative;
  width: px2rem(64);
  height: px2rem(64);
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.authorDetail {
  display: flex;
  flex-direction: column;
}

.authorName {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  color: var(--color-border-primary-lightest);
}

.authorRole {
  font-family: 'Montserrat-Book', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
  color: var(--color-border-primary-lightest);
}

.marquee {
  position: relative;
  height: px2rem(64);
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 140px;
    height: 100%;
    background: linear-gradient(90deg, #1D1A55 0%, rgba(29, 26, 85, 0) 100%);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 140px;
    height: 100%;
    background: linear-gradient(90deg, rgba(29, 26, 85, 0) 0%, #1D1A55 100%);
  }
}

.innermarquee {
  display: flex;
  position: relative;
  z-index: 1;
}

.item {
  display: flex;
  width: 112px;
  height: getSpacing(5);
  margin-right: 32px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.formcontent {
  margin-top: getSpacing(4);
}

.formwrapper {
  width: 100%;
  max-width: 488px;
  background-color: white;
  border-radius: 40px;
  box-shadow:
    0 179px 304px 0 rgba(33, 30, 95, 0.1),
    0 82.757px 140.548px 0 rgba(33, 30, 95, 0.07),
    0 47.352px 80.419px 0 rgba(33, 30, 95, 0.06),
    0 28.742px 48.814px 0 rgba(33, 30, 95, 0.05),
    0 17.318px 29.412px 0 rgba(33, 30, 95, 0.05),
    0 9.644px 16.379px 0 rgba(33, 30, 95, 0.04),
    0 4.148px 7.044px 0 rgba(33, 30, 95, 0.03);

  padding: getSpacing(5) getSpacing(2.5) getSpacing(2.5);

  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.formtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--Background-Background-primary-shady, #1D1A55);
  font-family: 'Montserrat-Book', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 116.667% */
  margin-bottom: getSpacing(2);
}

.formcontainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  grid-row: 1/-1;
}

// .form {
//   display: flex;
//   flex-wrap: wrap;
//   gap: getSpacing(2) getSpacing(1);

//   .inputcontainer {
//     width: 100%;

//     input {
//       @include text-misc-button;
//       color: var(--Background-Background-primary, #B5B4D2);
//       border-radius: 40px;
//       border: 1px solid var(--Background-Background-primary, #B5B4D2);
//       background: var(--global-basic-white, #FFF);
//       padding: getSpacing(2) getSpacing(3);
//       width: 100%;

//       &.errorinput {
//         border: 1px solid var(--Background-Background-primary, #FB6262);
//       }
//     }
//   }

//   .error {
//     color: var(--Color-system-Red, #FB6262);
//     @include text-body-body-xs-regular;
//     font-style: italic;
//   }

//   button.submitbutton {
//     width: 100%;
//     border-radius: 30px;
//     background: var(--Action-Action-primary, #221F61);
//     @include text-misc-button;
//     color: #FFF;
//     padding: getSpacing(2);
//   }
// }

// .formbonus {
//   width: 100%;
//   background-color: var(--color-action-acquisition);
//   border-radius: 16px;

//   display: flex;
//   gap: 0 getSpacing(2);
//   padding: getSpacing(1.5) getSpacing(2);
//   margin-bottom: getSpacing(2);

//   svg {
//     width: 42px;
//   }

//   p {
//     @include text-body-body-xs-bold;
//     color: var(--color-action-primary-hover);
//     width: calc(100% - 58px);
//   }
// }

// .formconfirm {
//   position: relative;
//   z-index: 2;
//   grid-column: 1/-1;
//   grid-row: 1/-1;
//   background-color: #FFF;

//   display: none;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

// .confirmtitle {
//   color: var(--Background-Background-primary-shady, #1D1A55);
//   text-align: center;
//   @include text-body-body-l-bold;
// }

// .confirmparagraph {
//   color: var(--Color-system-Navy-900, #221F61);
//   text-align: center;

//   /* Body/Body S Regular */
//   @include text-body-body-s-regular;
//   width: 100%;
//   max-width: 320px;
// }

.shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shapeleft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: var(--color-background-primary-shady, #140AD4);
}

.shaperight {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 50%;
  background: var(--color-background-primary-shady, #140AD4);
  border-bottom-right-radius: 60%;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md-v2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(22);
    padding-bottom: getSpacing(12);
  }

  .container {
    @include grid--lg-v2;
  }

  .textcontent {
    grid-column: 1/ span 6;
    text-align: left;
    align-items: flex-start;
  }

  .formcontent {
    grid-column: 8 / span 5;
    margin-top: getSpacing(0);
  }

  .testimonialAuthor {
    flex-direction: row;
  }

  // .form {
  //   .inputcontainer {
  //     &.inputfirstname,
  //     &.inputlastname {
  //       width: calc(50% - 4px);
  //     }
  //   }
  // }

  .shapeleft {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: var(--color-background-primary-shady, #140AD4);
  }

  .shaperight {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: var(--color-background-primary-shady, #140AD4);
    border-bottom-right-radius: 100%;
  }
}
