@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  padding-top: getSpacing(10);
  padding-bottom: getSpacing(10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: getSpacing(8);
  min-height: px2rem(640);
}

.container {
  position: relative;
  @include grid--xs-v2;
}

.wrapper {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titlelinkwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: getSpacing(2);
  margin-top: getSpacing(12);
  opacity: 0;
}

.title {
  font-family: 'Montserrat-Book', sans-serif;
  text-align: center;
  color: var(--color-text-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px; /* 200% */
}

.link {
  color: var(--Background-Background-highlight-dark, #6B30F6);
  text-align: center;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.logowrap {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 100%;
  // min-width: calc(100% + 140px);
  width: 1498px; // 1120pxof logos + 7*64pxgap (448) - 70px
  height: 100%;
  overflow: hidden;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 40px;
}

.logorow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  position: relative;
  width: px2rem(140);
  height: px2rem(60);
  padding: getSpacing(1);
  border-radius: 10px;
  background: var(--color-background-primary-white);
  box-shadow: 8px 16px 32px rgba(136, 141, 188, 0.16);
}

.logoinner {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--color-background-primary-white);

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md-v2;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg-v2;
  }
}
