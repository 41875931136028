@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  background: var(--color-background-primary-darkest);
  color: var(--color-white);
  height: 100vh;
  display: flex;
  align-items: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.container {
  position: relative;
  @include grid--xs;
}

.content {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  @include text-body-body-s-bold;
  text-transform: uppercase;
  margin-top: getSpacing(5);
}

.wizard {
  @include text-heading-heading-m;
  margin-top: getSpacing(5);
}

.paragraph {
  display: inline-block;
}

.select {
  display: inline-block;
  color: var(--color-text-highlight-alt);

  /* stylelint-disable no-descending-specificity */
  :global(.react-select__control) {
    background: none;
    border-radius: unset;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__control.react-select__control--is-focused) {
    position: relative;
    border: none;
    box-shadow: none;
    background: var(--text-text-highlight-gradiant);

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--color-background-primary-shady);
    }
  }

  :global(.react-select__control.react-select__control--menu-is-open) {
    box-shadow: unset;
  }

  :global(.react-select__value-container) {
    position: relative;
    padding: 0 0 0 getSpacing(1);

    &::after {
      content: '';
      position: absolute;
      bottom: getSpacing(1);
      left: 12px;
      width: 0;
      height: 2px;
      background: var(--color-text-highlight-alt);
      transition: width 0.25s ease-out;
    }
  }

  :global(.react-select__control:hover .react-select__value-container::after) {
    width: calc(100% - 12px);
  }

  :global(.react-select__single-value) {
    color: inherit;
  }

  :global(.react-select__indicators) {
    position: relative;
  }

  :global(.react-select__indicator) {
    padding: 0 getSpacing(1);
    color: var(--color-text-highlight-alt);

    &:hover {
      color: var(--color-text-highlight-alt);
    }
  }

  :global(.react-select__menu) {
    padding: 0;
    background: var(--color-background-primary-white);
    border-radius: getRadius('x-small');
    box-shadow: 37px 37px 84px rgba(136, 141, 188, 0.16);
    z-index: getZIndex('wizard');
  }

  :global(.react-select__menu-list) {
    padding: 0;
    border-radius: getRadius('x-small');
  }

  :global(.react-select__option) {
    padding: getSpacing(2) getSpacing(3);
    @include text-body-body-s-bold;
    color: var(--color-text-primary);

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__option--is-selected) {
    color: var(--color-text-highlight);
    background: none;
  }

  :global(.react-select__option--is-focused),
  :global(.react-select__option:active) {
    background: var(--color-background-primary-lightest);
  }

  :global(.react-select__input) {
    caret-color: transparent;
  }
  /* stylelint-enable no-descending-specificity */
}

.cta {
  margin-top: getSpacing(5);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    max-height: $page__max-height;
  }

  .background {
    width: 50%;
    background-position: right;
  }

  .container {
    @include grid--lg;
  }

  .content {
    grid-column: 3 / -3;
  }
}
