@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  position: relative;
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(8);
}

.filters {
  grid-column: 1 / -1;
}

.filtersButton {
  display: flex;
  justify-content: center;
  margin: getSpacing(6) 0 getSpacing(4);
}

.content {
  grid-column: 1 / -1;
}

.hr {
  grid-column: 1 / -1;
  margin: getSpacing(8) 0;
  border-top: 1px solid var(--color-border-primary-light);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }

  .filters {
    position: sticky;
    top: 0;
    grid-column: 1 / span 3;
  }

  .filtersButton {
    display: none;
  }

  .content {
    grid-column: span 5 / -1;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }

  .filters {
    position: sticky;
    top: 0;
    grid-column: 1 / span 3;
  }

  .content {
    grid-column: span 9 / -1;
  }
}
