@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding: getSpacing(12) $page__padding--xs;
}

.heading,
.subHeading,
.paragraph {
  grid-column: 1 / -1;
}

.heading {
  @include text-misc-subheading;
  margin-bottom: getSpacing(5);
  color: var(--color-text-highlight);

  &.isAcquisition,
  &.isMarketplace {
    color: var(--color-text-secondary);
  }
}

.subHeading {
  @include text-heading-heading-s;
  margin-bottom: getSpacing(2);

  &.isAcquisition {
    color: var(--color-text-acquisition);
  }

  &.isMarketplace {
    color: var(--color-text-marketplace);
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding: getSpacing(12) $page__padding--md;
  }
}

.section,
.paragraph {
  grid-column: 1 / 7;
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding: getSpacing(12) $page__padding--lg;
  }

  .heading {
    grid-column: 1 / 11;
  }

  .subHeading {
    grid-column: 1 / 6;
    margin-bottom: 0;
  }

  .paragraph {
    grid-column: 6 / 11;
  }
}
