@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding-bottom: getSpacing(12);
}

.headingAcquisition,
.headingMarketplace,
.paragraph,
.cards,
.cardsLarge,
.cardLarge,
.buttonTertiary,
.cardSmall,
.channelSelected {
  grid-column: 1 / -1;
}

.paragraph,
.cardLarge,
.cardSmall,
.channelSelected {
  margin-bottom: getSpacing(4);
}

.headingAcquisition,
.headingMarketplace {
  @include text-misc-subheading;
  margin-bottom: getSpacing(3);
}

.headingAcquisition {
  color: var(--color-text-acquisition);
}

.headingMarketplace {
  color: var(--color-text-marketplace);
}

.paragraph {
  color: var(--color-text-primary);
}

.buttonSecondary {
  grid-column: 1 / 5;
  margin-bottom: getSpacing(8);
}

.cardsLarge,
.cardsSmall {
  @include grid--xs;
  padding: 0;
  margin: 0;
  grid-auto-flow: row dense;
}

.buttonTertiary {
  @include width-fit-content();
  margin-top: getSpacing(4);
  grid-column: 1 / -1;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-bottom: getSpacing(12);
  }

  .paragraph,
  .buttonSecondary,
  .cardLarge {
    grid-column: 1 / -1;
  }

  .paragraph {
    margin-bottom: getSpacing(8);
  }

  .cardsLarge {
    @include grid--md;
    padding: 0;
  }

  .cardsSmall {
    @include grid--md;
    padding: 0;
  }

  .cardSmall {
    grid-column: span 4;
  }

  .buttonSecondary {
    @include width-fit-content();
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-bottom: getSpacing(12);
  }

  .paragraph {
    grid-column: 1 / 9;
  }

  .buttonSecondary {
    grid-column: 9 / -1;
    justify-self: end;
    align-self: end;
  }

  .cardsLarge {
    @include grid--lg;
    padding: 0;
  }

  .cardLarge {
    grid-column: span 4;
  }

  .cardsSmall {
    @include grid--lg;
    padding: 0;
    grid-template-columns: repeat(5, 1fr);
  }

  .cardSmall {
    grid-column: span 1;
  }
}
