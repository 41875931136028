@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.title {
  @include text-misc-subheading;
}

.address {
  margin-top: getSpacing(1);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------
