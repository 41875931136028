@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  display: flex;
  align-items: center;
  cursor: default;

  &:hover {
    cursor: default;
  }
}

.select {
  color: var(--color-text-primary);

  /* stylelint-disable no-descending-specificity */
  :global(.react-select__control) {
    @include text-misc-button();
    background: none;
    border-radius: unset;
    border: none;
    min-height: auto;
    transition: none;
  }

  :global(.react-select__value-container) {
    padding: 0 getSpacing(0.5) 0 getSpacing(1);
    @include text-misc-button;
  }

  :global(.react-select__single-value) {
    color: inherit;
  }

  :global(.react-select__indicator) {
    padding: 0;
    color: inherit;

    &:hover {
      color: var(--color-text-highlight-alt);
    }
  }

  :global(.react-select__menu) {
    right: -40%;
    width: auto;
    margin: getSpacing(2) 0;
    padding: 0;
    background: var(--color-background-primary-white);
    border-radius: getRadius('x-small');
    box-shadow: 37px 37px 84px rgba(136, 141, 188, 0.16);
    z-index: getZIndex('languages-menu');
  }

  :global(.react-select__menu-list) {
    padding: 0;
    border-radius: getRadius('x-small');
  }

  :global(.react-select__option) {
    padding: getSpacing(2) getSpacing(3);
    @include text-body-body-s-bold;
  }

  :global(.react-select__option--is-selected) {
    color: var(--color-text-highlight);
    background: none;
  }

  :global(.react-select__option--is-focused),
  :global(.react-select__option:active) {
    background: var(--color-background-primary-lightest);
  }
  /* stylelint-enable no-descending-specificity */
}

.isLight {
  /* stylelint-disable no-descending-specificity */
  :global(.react-select__indicator),
  :global(.react-select__single-value) {
    color: var(--color-white);
  }

  path {
    fill: var(--color-white);
  }
  /* stylelint-enable no-descending-specificity */
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .select :global(.react-select__menu) {
    right: 0;
  }
}
