@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  position: relative;
}

.index {
  @include text-heading-display-xl;
  display: block;
  position: absolute;
  top: 0;
  left: -8px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: var(--color-border-highlight-dark);
  -webkit-text-stroke-width: 1px;
}

.heading {
  position: relative;
  @include text-heading-heading-s;
  padding-top: getSpacing(16);
}

.paragraph {
  margin-top: getSpacing(2);

  .isLight & {
    color: var(--color-text-secondary);
  }
}

.button {
  margin-top: getSpacing(6);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 $grid__gap--md;
  }

  .index {
    position: relative;
    display: block;
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .heading {
    grid-row: 1;
    grid-column: 2 / -1;
    padding-top: getSpacing(14);
  }

  .paragraph {
    grid-column: 2 / -1;
  }

  .button {
    grid-column: 2 / -1;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    grid-template-columns: repeat(5, 1fr);
  }
}
