@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  background-color: var(--color-background-primary-darkest);
  overflow: hidden;
}

.container {
  @include grid--xs;
  position: relative;
  justify-items: center;
  place-items: center;
  padding-top: getSpacing(8);
  padding-bottom: getSpacing(12);
  background-color: var(--color-background-primary-light);
  color: var(--color-text-primary);
  border-bottom-right-radius: 35% 20%;
  overflow: hidden;

  .isAcquisition & {
    color: var(--color-text-acquisition);
    background-color: var(--color-background-acquisition-light);
  }

  .isMarketplace & {
    color: var(--color-text-marketplace);
    background-color: var(--color-background-marketplace-light);
  }
}

.shape {
  position: absolute;
  top: 0;
  left: -20%;
  height: 100%;
  width: auto;
  color: var(--color-background-primary-lightest);

  .isAcquisition &,
  .isMarketplace & {
    color: var(--color-background-primary-white);
  }
}

.heading,
.button {
  grid-column: 1 / -1;
}

.heading {
  @include text-heading-heading-m;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: getSpacing(4);
}

.button {
  @include width-fit-content();
  position: relative;
  z-index: 1;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
    padding-top: getSpacing(14);
    padding-bottom: getSpacing(14);
    border-bottom-right-radius: 25% 40%;
  }

  .shape {
    left: 35%;
  }

  .heading {
    grid-column: 1 / 6;
    justify-self: start;
    text-align: left;
    margin-bottom: 0;
  }

  .button {
    grid-column: 6 / -1;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
    padding-top: getSpacing(19);
    padding-bottom: getSpacing(19);
    border-bottom-right-radius: 25% 80%;
  }

  .shape {
    width: 50%;
    height: 100%;
    top: unset;
    bottom: 0;
    left: 50%;
  }

  .button {
    grid-column: 8 / -1;
  }
}

@media (min-width: $page__max-width) {
  .section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100vw - #{$page__max-width}) / 2 + 2px);
    height: 100%;
    background-color: var(--color-background-primary-light);
  }

  .section.isAcquisition::after {
    background-color: var(--color-background-acquisition-light);
  }

  .section.isMarketplace::after {
    background-color: var(--color-background-marketplace-light);
  }
}
