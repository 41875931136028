@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  XS
// ------------------------------------------------------------

.marquee {
  overflow-x: hidden;
  width: 100%;
}

.container {
  display: flex;
  white-space: nowrap;

  &.justend {
    justify-content: end;
  }

  &.juststart {
    justify-content: start;
  }
}

.inline {
  display: inline;
}
