@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  overflow: hidden;
  background-color: var(--color-navy-100);
  padding-top: getSpacing(9);
  padding-bottom: getSpacing(9);
}

.container {
  @include grid--xs-v2;
}

.picture,
.content {
  position: relative;
  grid-column: 1 / -1;
}

.innerpicture {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-primary);
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 16px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.heading {
  @include text-heading-heading-m;
  text-align: center;

  & b {
    @include text-gradient;
  }
}

.paragraph {
  @include text-body-body-s-regular;
  margin-top: getSpacing(4);

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 16px;

    li {
      position: relative;
      font-family: 'Montserrat-Medium', sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--color-text-primary);
      text-transform: uppercase;
      width: 100%;
      text-align: left;
      padding: 0 0 0 40px;

      &::before {
        content: '';
        height: 31px;
        width: 31px;
        background-image: url('../../assets/listImage.svg');
        background-repeat: no-repeat;
        position: absolute;
        top: -5px;
        left: 0;
      }
    }
  }
}

.button {
  margin-top: getSpacing(4);
  margin-bottom: getSpacing(4);
  display: flex;
  justify-content: center;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md-v2;
    padding-top: getSpacing(2);
    padding-bottom: getSpacing(12);
  }

  .paragraph {
    ul {
      li {
        position: relative;
        width: calc(50% - 8px);
      }
    }
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(12);
    padding-bottom: getSpacing(12);
  }

  .container {
    @include grid--lg-v2;
  }

  .content {
    grid-column: 1 / span 7;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    grid-row: 1/-1;
  }

  .heading {
    text-align: left;
  }

  .picture {
    grid-column: 8 / span 5;
    display: flex;
    align-items: center;
    grid-row: 1/-1;
  }

  .paragraph {
    @include text-body-body-s-regular;
    margin-top: getSpacing(4);

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 4px 16px;

      li {
        position: relative;
        width: calc(50% - 8px);
        font-family: 'Montserrat-Medium', sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--color-text-primary);
        text-transform: uppercase;
        text-align: left;

        min-height: 48px;
        display: flex;
        align-items: center;

        padding: 0 0 0 40px;
        border: none;

        &::before {
          content: '';
          height: 31px;
          width: 31px;
          background-image: url('../../assets/listImage.svg');
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  .button {
    justify-content: flex-start;
  }

  .isFlipped {
    .content {
      grid-column: 6 / span 7;
    }

    .picture {
      grid-column: 1 / span 5;
    }
  }
}
