@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  background: var(--color-background-primary-lightest);
  overflow: hidden;
  padding-top: getSpacing(10);
}

.container {
  @include grid--xs;
}

.heading {
  @include text-misc-subheading;
  grid-column: 1 / -1;
  color: var(--color-text-highlight);
  margin-bottom: getSpacing(2);
}

.paragraph {
  @include text-heading-heading-m;
  grid-column: 1 / -1;
  margin-bottom: getSpacing(4);
}

.buttons {
  grid-column: 1 / -1;
  display: flex;
  margin-bottom: getSpacing(8);
}

.button:not(:last-child) {
  margin-right: getSpacing(2);
}

.items {
  grid-column: 1 / -1;
  width: 100%;
  margin-bottom: getSpacing(12);
  display: flex;
  flex-flow: row nowrap;
  overflow: visible;
}

.item {
  flex: 0 0 100%;
  display: flex;
  padding-right: $grid__gap--xs;
}

.cta {
  grid-column: 1 / -1;
  margin-bottom: getSpacing(16);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
  }

  .items {
    grid-column: 1 / span 4;
  }

  .item {
    padding-right: $grid__gap--md;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
  }

  .paragraph {
    @include text-heading-heading-m;
    grid-column: 1 / 11;
  }

  .buttons {
    grid-column: 11 / -1;
    justify-self: end;
  }

  .item {
    padding-right: $grid__gap--lg;
  }
}
