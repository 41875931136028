@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.shape {
  position: absolute;
  top: 0;
  left: -50%;
  width: 150%;
  height: 100%;
}

.watermark {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%);
}

.watermark.s {
  display: block;
}

.logo {
  display: none;
}

.content {
  position: relative;
  grid-column: 1 / -1;
  color: var(--color-text-white);
  margin-top: getSpacing(20);
  margin-bottom: getSpacing(21);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  margin-bottom: getSpacing(4);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    min-height: 100vh;
  }

  .background {
    position: relative;
    left: 0;
    grid-row: 1;
    grid-column: 2 / -1;
    width: calc(100% + #{$page__padding--md});
    height: 100%;
  }

  .watermark {
    &.s {
      display: none;
    }

    &.m {
      display: block;
    }
  }

  .shape {
    left: 0;
    width: 100%;
  }

  .content {
    grid-row: 1;
    grid-column: 4 / -1;
    margin-top: getSpacing(28);
    margin-bottom: getSpacing(28);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    max-height: $page__max-height;
    align-items: center;
  }

  .background {
    grid-column: 3 / -1;
    width: calc(100% + #{$page__padding--lg});
  }

  .watermark {
    transform: translate(10%, -50%);

    &.m {
      display: none;
    }

    &.l {
      display: block;
    }
  }

  .logo {
    display: block;
    position: absolute;
    left: 0;
    top: 30%;
    transform: translate(0, -50%);
  }

  .content {
    grid-column: 5 / -2;
    margin: 0;
  }
}

@media (min-width: $page__max-width + 1) {
  .background {
    width: calc(100% + #{$page__padding--lg} + ((100vw - #{$page__max-width}) / 2));
  }

  .watermark {
    right: calc(((100vw - #{$page__max-width}) / 2));
    transform: translate(0, -50%);
  }

  .logo {
    left: 2%;
  }
}
