@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  width: 100%;
  overflow: hidden;
  background-color: var(--color-border-primary-lightest);
  padding-top: getSpacing(10);
  padding-bottom: getSpacing(10);
}

.heading {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  color: var(--color-text-highlight);
  grid-column: span 4;
  text-align: center;
}

.content {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 3rem;
  grid-column: span 4;
  text-align: center;
  color: var(--color-text-primary);
  margin-bottom: getSpacing(6);
}

.container {
  @include grid--xs;
  position: relative;
}

.cardsOutterContainer {
  width: 100%;
  position: relative;
}

.illustration {
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed var(--color-border-primary);
  }
}

.arrowSVG {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);

  &:nth-child(2) {
    transform: translate(#{getSpacing(5)}, -50%);
  }

  &.isRight {
    left: unset;
    right: 10%;

    &:nth-child(4) {
      transform: translate(#{getSpacing(5) * -1}, -50%);
    }
  }
}

.arrowPath {
  fill: none;
  stroke: var(--color-border-primary);
  stroke-dasharray: 1;
}

.cards {
  position: relative;
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  gap: getSpacing(2);
}

.card {
  display: flex;
  flex-direction: column;
  gap: getSpacing(3);
  text-align: center;
  background-color: var(--color-white);
  padding: getSpacing(4);
  border-radius: 10px;
  border: 1px solid var(--color-border-highlight);
  box-shadow: 37px 37px 84px 0 rgba(33, 30, 95, 0.1);
}

.cardNumbers {
  font-family: Montserrat-Medium, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 114.286% */

  background: #8ADCFE;
  background: linear-gradient(to right, #8ADCFE 0%, #9479F7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardContent {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5rem;
  color: var(--color-text-primary);

  .bold {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-top: getSpacing(10);
    padding-bottom: getSpacing(10);
  }

  .container {
    @include grid--md;
  }

  .content,
  .heading {
    grid-column: span 8;
  }

  .cards {
    grid-column-start: 2;
    grid-column-end: 8;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(10);
    padding-bottom: getSpacing(10);
  }

  .container {
    @include grid--lg;
  }

  .heading {
    margin-bottom: getSpacing(1);
  }

  .content,
  .heading {
    grid-column: span 13;
  }

  .cards {
    grid-column-start: 3;
    grid-column-end: 11;
    flex-direction: row;
    gap: getSpacing(4);
  }

  .card {
    flex: 1;
  }
}
