@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  padding-top: getSpacing(10);
  padding-bottom: getSpacing(14);
  overflow: hidden;
}

.section.isAlternative {
  background-color: var(--color-background-primary-darkest);
  color: var(--color-text-white);
}

.container {
  position: relative;
  @include grid--xs;
}

.arrowRound,
.arrowCross {
  display: none;
}

.heading {
  position: relative;
  grid-column: 1 / -1;
  @include text-heading-heading-l();

  & b {
    @include text-gradient();
  }
}

.paragraph,
.secondParagraph {
  position: relative;
  @include text-body-body-s-regular;
  grid-column: 1 / -1;
  margin-top: getSpacing(5);
}

.pictures {
  position: relative;
  grid-column: 1 / -1;
  @include grid--xs;
  margin: getSpacing(10) 0 0;
  padding: 0;
}

.picture {
  position: relative;
  display: none;
}

.badge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.4s ease-out;
  filter: drop-shadow(55px 55px 110px rgba(34, 33, 93, 0.12));

  &:hover {
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.section .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.picture-0,
.picture-2 {
  padding-top: 62.5%;
}

.picture-1,
.picture-3 {
  padding-top: 114%;
}

.picture-0 {
  display: block;
  grid-column: 1 / -1;
  width: calc(100% + 2 * $page__padding--xs);
  margin-left: -1 * $page__padding--xs;
  margin-right: -1 * $page__padding--xs;
}

.picture-1 {
  display: block;
  grid-column: 2 / span 3;
  top: getSpacing(-4);
  left: -1 * $grid__gap--xs;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-bottom: getSpacing(25);
  }

  .container {
    @include grid--md;
  }

  .arrowRound,
  .arrowCross {
    display: block;
    position: absolute;
    right: - getSpacing(14);
  }

  .arrowRound {
    top: 0;
  }

  .arrowCross {
    bottom: 0;
  }

  .paragraph {
    margin-top: getSpacing(8);
    grid-column: 4 / span 4;
  }

  .pictures {
    @include grid--lg;
    margin: getSpacing(8) 0 0;
    padding: 0;
    width: 160%;
    position: relative;
    left: -20%;
  }

  .picture {
    display: block;
    width: auto;
  }

  .picture-0 {
    grid-column: 7 / -2;
  }

  .picture-1 {
    grid-column: 8 / -3;
  }

  .picture-2 {
    grid-row: 1;
    grid-column: 1 / 6;
    top: 50%;
  }

  .picture-3 {
    grid-row: 1;
    grid-column: 2 / 5;
    top: -33%;
  }

  .secondParagraph {
    position: relative;
    margin-top: -1 * getSpacing(14);
    grid-column: 2 / span 3;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(10) + $page__nav-height--lg;
  }

  .container {
    @include grid--lg;
  }

  .arrowRound,
  .arrowCross {
    right: getSpacing(14);
  }

  .heading {
    @include text-heading-heading-xl();
    grid-column: 2 / -3;
  }

  .paragraph {
    grid-column: 6 / span 4;
  }

  .pictures {
    width: auto;
    left: 0;
  }

  .secondParagraph {
    @include text-heading-heading-s;
    margin-top: -1 * getSpacing(25);
    grid-column: 4 / span 3;
  }
}
