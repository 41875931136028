@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.testimonial {
  @include text-heading-heading-xs;
  margin-bottom: getSpacing(3);
}

.author {
  @include text-misc-subheading;
  margin-bottom: getSpacing(5);
}

.job {
  opacity: 0.4;
}

.picture {
  @include flex--center;
  position: relative;
  width: getSpacing(11);
  height: getSpacing(11);
  margin-top: auto;
}

.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.circle {
  stroke-width: 2px;
  stroke: var(--color-background-highlight-light);
}

.loader {
  @extend .circle;
  stroke: var(--color-background-primary-darkest);
}

.img {
  width: getSpacing(9);
  height: getSpacing(9);
  border-radius: 50%;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------
