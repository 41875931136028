@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  padding-top: getSpacing(2);
  padding-bottom: getSpacing(2);
}

.content {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: getSpacing(3);
  margin-bottom: getSpacing(5);
  text-align: center;
}

.heading {
  @include text-misc-subheading;
  color: var(--color-text-highlight);
}

.paragraph {
  @include text-heading-heading-m;
}

.bold {
  display: inline-block;
  padding: getSpacing(0) getSpacing(2);
  line-height: 1.25em;
  border-radius: getRadius('rounded');
  color: var(--color-white);
  background: linear-gradient(66.04deg, #9479F7 0%, #DCDCFF 99.98%);
}

.caption {
  @include text-body-body-s-regular;
  color: var(--color-text-secondary);
}

.images {
  grid-column: 1 / -1;
}

.image:not(.isActive) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.select {
  display: inline-block;
  color: var(--color-text-highlight-alt);

  /* stylelint-disable no-descending-specificity */
  :global(.react-select__control) {
    background: none;
    border-radius: unset;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__control.react-select__control--is-focused) {
    position: relative;
    border: none;
    box-shadow: none;
    background: var(--text-text-highlight-gradiant);

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--color-background-primary-lightest);
    }
  }

  :global(.react-select__control.react-select__control--menu-is-open) {
    box-shadow: unset;
  }

  :global(.react-select__value-container) {
    position: relative;
    padding: 0 0 0 getSpacing(1);

    &::after {
      content: '';
      position: absolute;
      bottom: getSpacing(1);
      left: 12px;
      width: 0;
      height: 2px;
      background: var(--color-text-highlight-alt);
      transition: width 0.25s ease-out;
    }
  }

  :global(.react-select__control:hover .react-select__value-container::after) {
    width: calc(100% - 12px);
  }

  :global(.react-select__single-value) {
    color: inherit;
  }

  :global(.react-select__indicators) {
    position: relative;
  }

  :global(.react-select__indicator) {
    padding: 0 getSpacing(1);
    color: var(--color-text-highlight-alt);

    &:hover {
      color: var(--color-text-highlight-alt);
    }
  }

  :global(.react-select__menu) {
    padding: 0;
    background: var(--color-background-primary-white);
    border-radius: getRadius('x-small');
    box-shadow: 37px 37px 84px rgba(136, 141, 188, 0.16);
    z-index: getZIndex('wizard');
  }

  :global(.react-select__menu-list) {
    padding: 0;
    border-radius: getRadius('x-small');
  }

  :global(.react-select__option) {
    padding: getSpacing(2) getSpacing(3);
    @include text-body-body-s-bold;
    color: var(--color-text-primary);

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-select__option--is-selected) {
    color: var(--color-text-highlight);
    background: none;
  }

  :global(.react-select__option--is-focused),
  :global(.react-select__option:active) {
    background: var(--color-background-primary-lightest);
  }

  :global(.react-select__input) {
    caret-color: transparent;
  }
  /* stylelint-enable no-descending-specificity */
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
    padding-bottom: getSpacing(8);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(0);
    padding-bottom: getSpacing(0);
  }

  .content {
    grid-column: 1 / 6;
    margin: 0;
    text-align: left;
  }

  .images {
    grid-column: 6 / -1;
    height: 100vh;
    max-height: $page__max-height;
    overflow: hidden;
  }

  .image {
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
}
