@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: white;
  overflow: auto;
  padding: getSpacing(3);

  &.isVisible {
    display: block;
  }
}

.count {
  @include text-body-body-s-bold;
  color: var(--color-text-muted);
  padding-bottom: getSpacing(2);
  border-bottom: 1px solid var(--color-border-primary-light);

  display: none;
}

.search {
  position: relative;
  margin: getSpacing(2) 0 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: getSpacing(2);
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7345 10.3986L8.92202 7.58608C9.55484 6.64858 9.88296 5.47671 9.7189 4.21108C9.41421 2.05483 7.6564 0.32046 5.52358 0.0392099C2.33608 -0.359228 -0.359228 2.33608 0.0392099 5.52358C0.32046 7.6564 2.05483 9.41421 4.21108 9.7189C5.47671 9.88296 6.64858 9.55484 7.60952 8.92202L10.3986 11.7345C10.7736 12.0861 11.3595 12.0861 11.7345 11.7345C12.0861 11.3595 12.0861 10.7736 11.7345 10.3986ZM1.86733 4.86733C1.86733 3.22671 3.20327 1.86733 4.86733 1.86733C6.50796 1.86733 7.86733 3.22671 7.86733 4.86733C7.86733 6.5314 6.50796 7.86733 4.86733 7.86733C3.20327 7.86733 1.86733 6.5314 1.86733 4.86733Z' fill='%2363618F'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
}

.input {
  @include text-body-body-xs-regular;
  width: 100%;
  padding: getSpacing(2);
  padding-left: getSpacing(4.5);
  border-radius: getRadius('small');
  border: 1px solid var(--color-border-highlight-light);

  &:focus {
    outline: 2px solid var(--color-border-highlight-light);
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.heading {
  @include text-body-body-s-bold;
  margin-top: getSpacing(4);

  .header & {
    margin-top: 0;
  }
}

.closeIcon {
  flex: 0 0 auto;
  margin-left: getSpacing(2);
}

.buttons {
  display: flex;
  gap: getSpacing(1);
  flex-wrap: wrap;
  margin: getSpacing(2) 0 0;
}

.button {
  @include text-body-body-xs-regular;
  color: var(--color-text-primary);
  display: block;
  margin: 0;
  padding: getSpacing(1) getSpacing(2);
  border-radius: getRadius('regular');
  border: 1px solid var(--color-border-highlight-light);
  background: var(--color-background-primary-white);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus,
  &:focus:not(:focus-visible) {
    border: 1px solid var(--color-border-highlight-light);
    outline: 2px solid var(--color-border-highlight-light);
  }

  &.isActive {
    background: var(--color-background-highlight-dark);
    color: var(--color-text-white);
    border: 1px solid var(--color-background-highlight-dark);
    outline: none;
  }
}

.closeButton {
  margin-top: getSpacing(4);
  display: flex;
  justify-content: center;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    display: block;
    position: relative;
    height: auto;
    overflow: visible;
    padding: 0;
  }

  .count {
    display: block;
  }

  .closeIcon {
    display: none;
  }

  .heading,
  .header .heading {
    margin-top: getSpacing(4);
  }

  .closeButton {
    display: none;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------
