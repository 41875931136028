@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  grid-column: 1 / -1;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.marketplace {
  background-color: var(--color-background-marketplace);
}

.acquisition {
  background-color: var(--color-background-acquisition);
}

.marketplace,
.acquisition {
  position: relative;
  @include flex--center;
  flex-basis: 50%;
  padding: getSpacing(22) getSpacing(2) getSpacing(18);
  overflow: hidden;
  text-decoration: none;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading {
  position: relative;
  @include text-heading-heading-m;
  margin-bottom: getSpacing(4);
  color: var(--color-text-white);
}

.paragraph {
  position: relative;
  z-index: 1;
  @include text-body-body-s-regular;
  margin-bottom: getSpacing(6);
  color: var(--color-white);
}

.button {
  @include width-fit-content();
  margin: 0 auto;
}

.circles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  stroke: var(--color-border-primary-light);
  opacity: 0.6;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .marketplace,
  .acquisition {
    padding: getSpacing(22) getSpacing(14) getSpacing(18);
  }

  .heading {
    margin-bottom: getSpacing(5);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    flex-direction: row;
  }

  .marketplace,
  .acquisition {
    padding-left: 0;
    padding-right: 0;
    transition: all 0.4s ease;

    &:hover {
      flex-basis: 56%;
    }
  }

  .heading,
  .paragraph {
    width: calc(50vw - 2 * #{getSpacing(14)});
  }
}
