@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.wrapper {
  position: relative;
  background-color: var(--color-background-primary-lightest);
  overflow: hidden;

  &.paddings {
    padding-top: getSpacing(6);
    padding-bottom: getSpacing(6);
  }

  &.acquisition,
  &.marketplace,
  &.dark,
  &.ailice {
    color: var(--color-text-white);
  }

  &.acquisition {
    background-color: var(--color-background-acquisition);
  }

  &.marketplace {
    background-color: var(--color-background-marketplace);
  }

  &.dark,
  &.ailice {
    background-color: var(--color-border-primary-darkest);
  }
}

.section {
  @include grid--xs-v2;
  position: relative;
  padding-top: getSpacing(4);
  padding-bottom: getSpacing(6);
}

.article {
  grid-column: 1 / -1;
  position: relative;
  z-index: 1;
  align-self: center;
}

.heading,
.headingPrimary {
  @include text-heading-heading-m;
  margin-bottom: getSpacing(4);
  color: var(--text-color-white);

  & b {
    font-weight: normal;
  }
}

.headingAilice {
  font-family: Montserrat-Light, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 64px; /* 114.286% */

  background: #8ADCFE;
  background: linear-gradient(to right, #77CEF2 0%, #9479F7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paragraph {
  @include text-body-body-s-regular;
  margin-bottom: getSpacing(4);
  width: 80%;

  &.paragraphAilice {
    font-family: Montserrat-Light, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 44.8px */
    width: 100%;
    max-width: 545px;
  }
}

.marquee {
  margin: getSpacing(6) 0;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #F7F6FB 0%, rgba(247, 246, 251, 0) 10%, rgba(255, 255, 255, 0) 90%, #FFF 100%);
  }
}

.logo {
  padding: 0 getSpacing(3);
}

.logoImage {
  height: 40px;
  width: auto;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: getSpacing(4);
}

.videowrapper {
  display: none;
}

.image,
.imageClipped {
  display: none;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.bgCorner {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  stroke: var(--color-white);

  &.acquisition {
    fill: var(--color-background-acquisition-light);
  }

  &.marketplace {
    fill: var(--color-background-marketplace-light);
  }

  &.dark,
  &.ailice {
    fill: var(--color-border-primary-darkest);
  }
}

.videowrapperAilice {
  grid-column: 1 / -1;
  grid-row: 2/3;
  display: block;
  padding-top: getSpacing(4);

  .imagecontainer {
    width: 100%;
    max-width: 600px;
  }

  .image {
    position: relative;
    display: block;
    width: 100%;
  }
}

.rectAilice {
  display: none;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  height: 370px;
  background: linear-gradient(256deg, #6BC8EE -0.2%, #D9D3FF 81.89%);
  left: calc(50% + 95px);
  width: 50%;
  border-top-left-radius: 185px;
  border-bottom-left-radius: 185px;
}

.bgCircleWrapper {
  display: none;
}

.roller {
  position: relative;
  z-index: -1;
}

.placeholder {
  display: inline-block;
  visibility: hidden;
}

.item {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.headingPrimary b,
.headingPrimary b .roller,
.headingPrimary b .item {
  color: var(--color-text-highlight-alt);
  background: var(--text-text-highlight-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md-v2;
    padding-top: getSpacing(6);
    padding-bottom: getSpacing(6);
  }

  .heading,
  .headingPrimary {
    @include text-heading-heading-m;
  }

  .headingAilice {
    font-family: Montserrat-Light, sans-serif;
    font-size: 56px;
    line-height: 64px; /* 114.286% */

    background: #9479F7;
    background: linear-gradient(294deg, #77CEF2 0%, #9479F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bgCorner {
    width: 50%;
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .wrapper {
    &.paddings {
      padding-top: getSpacing(16);
      padding-bottom: getSpacing(13);
    }
  }

  .section {
    @include grid--lg-v2;
    padding-top: getSpacing(4);
    padding-bottom: getSpacing(0);
    position: relative;
  }

  .videowrapper {
    display: block;
    grid-column: 6 / -1;
    position: relative;
    z-index: 1;
    align-self: center;
  }

  .videowrapperAilice {
    grid-column: 7 / -1;
    grid-row: 1/-1;
  }

  .article {
    grid-column: 1 / 6;
    grid-row: 1/-1;
    padding-top: getSpacing(10);
    padding-bottom: getSpacing(10);

    &.articleAilice {
      grid-column: 1 / 7;
    }
  }

  .marquee::after {
    background: linear-gradient(90deg, #F7F6FB 0%, rgba(247, 246, 251, 0) 15%, rgba(247, 246, 251, 0) 85%, #F7F6FB 100%);
  }

  .bgCorner {
    padding-top: $page__nav-height--lg;
  }

  .rectAilice {
    display: block;
  }

  .bgCircleWrapper {
    @include grid--lg-v2;
    height: 100%;
    padding-top: $page__nav-height--lg;
    position: absolute;
    left: 0;
    width: 100%;
    max-width: none;
  }

  .bgCircleContainer {
    overflow: hidden;
    grid-column: 8 / -1;
    height: 100%;
    width: calc(100% + #{$page__padding--lg});
    display: flex;
  }

  .bgCircle {
    flex: 0 0 auto;
    height: 100%;
    width: auto;

    &.acquisition {
      fill: var(--color-background-acquisition-light);
    }

    &.marketplace {
      fill: var(--color-background-marketplace-light);
    }

    &.dark,
    &.ailice {
      fill: var(--color-background-marketplace-light);
    }
  }

  .bgCircleFill {
    flex: 1 1 auto;

    &.acquisition {
      background-color: var(--color-background-acquisition-light);
    }

    &.marketplace {
      background-color: var(--color-background-marketplace-light);
    }

    &.dark,
    &.ailice {
      background-color: var(--color-border-primary-darkest);
    }
  }

  .clipPath {
    flex: 0 0 auto;
    height: 0;
    width: 0;
  }

  .clipContainer {
    position: relative;
    height: 100%;
    align-self: center;
    margin-left: auto;
    justify-self: flex-end;
    aspect-ratio: 1;
  }

  .imageClipped {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    clip-path: url(#HeroSimpleClipPath);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .image {
    position: relative;
    display: block;
    width: 100%;
  }
}
