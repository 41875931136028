@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  overflow: hidden;
}

.container {
  @include grid--xs;
  padding-top: getSpacing(2);
  padding-bottom: getSpacing(14);
}

.picture,
.content {
  position: relative;
  grid-column: 1 / -1;
}

.picture {
  padding-top: 100%;
}

.shape {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1 * $page__padding--xs;
  width: auto;
  height: calc(100% + #{getSpacing(4)});
}

.shape,
.picture::after {
  color: var(--color-background-primary-lightest);
}

.isFlipped .shape {
  right: unset;
  left: -1 * $page__padding--xs;
  transform: translateY(-50%) scaleX(-1);
}

.marketplace .shape,
.marketplace .picture::after {
  color: var(--color-background-marketplace-light);
}

.acquisition .shape,
.acquisition .picture::after {
  color: var(--color-background-acquisition-light);
}

.image,
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.subheading {
  @include text-misc-subheading;
  color: var(--color-text-highlight);
  margin-top: getSpacing(7);
  margin-bottom: getSpacing(4);

  &.marketplace {
    color: var(--color-text-marketplace);
  }

  &.acquisition {
    color: var(--color-text-acquisition);
  }
}

.heading {
  @include text-heading-heading-m;

  & b {
    @include text-gradient;
  }
}

.paragraph {
  @include text-body-body-s-regular;
  margin-top: getSpacing(4);
}

.button {
  margin-top: getSpacing(4);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
    padding-top: getSpacing(2);
    padding-bottom: getSpacing(12);
  }

  .shape {
    right: -1 * $page__padding--md;
    width: calc(100% - #{$page__padding--md});
  }

  .isFlipped .shape {
    left: -1 * $page__padding--md;
  }

  .subheading {
    margin-top: getSpacing(12);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
    padding-top: getSpacing(12);
    padding-bottom: getSpacing(12);
    grid-auto-flow: dense;
  }

  .shape {
    right: -1 * $page__padding--lg;
    height: calc(100% + #{getSpacing(12)} * 2);
    top: -1 * getSpacing(12);
    transform: none;
  }

  .content {
    grid-column: 1 / span 5;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .subheading {
    margin-top: 0;
  }

  .picture {
    grid-column: 6 / span 7;
    @include grid--center;

    &:not(.isVideo)::after {
      content: '';
      position: absolute;
      top: -1 * getSpacing(12);
      left: 100%;
      width: 100vw;
      height: calc(100% + 2 * #{getSpacing(12)});
      background: currentColor;
    }
  }

  .isFlipped {
    .shape {
      left: -1 * ($page__padding--lg + getSpacing(8));
      transform: scaleX(-1);
      width: 100%;
    }

    .picture::after {
      left: unset;
      right: 100%;
    }

    .content {
      grid-column: 8 / span 5;
    }

    .picture {
      grid-column: 1 / span 7;
    }
  }
}
