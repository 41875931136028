@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  padding-top: getSpacing(9);
  padding-bottom: getSpacing(9);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(3);
}

.heading {
  grid-column: 1 / -1;
  text-align: center;
  @include text-heading-heading-s;
  color: var(--color-text-primary);
}

.container {
  @include grid--xs-v2;
}

.wrapper {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: getSpacing(4) 0;
}

.logoListItem {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  width: px2rem(176);
  height: px2rem(64);
  position: relative;
}

.innerlogo {
  position: relative;
  height: 100%;

  img {
    object-position: left;
    object-fit: contain;
    height: 100%;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-background-acquisition);
  margin-bottom: getSpacing(4);
  margin-top: getSpacing(3);
}

.text {
  width: 100%;
  font-family: 'Montserrat-Book', sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    padding-top: getSpacing(7);
    padding-bottom: getSpacing(7);
  }

  .container {
    @include grid--md-v2;
  }

  .logoListItem {
    flex-direction: row;
  }

  .separator {
    width: px2rem(1);
    height: 100%;
    margin-right: getSpacing(4);
    margin-bottom: 0;
    margin-top: 0;
  }

  .text {
    width: calc(100% - 209px);
  }

  .logo {
    width: px2rem(176);
    position: relative;
    height: 100%;
  }

  .innerlogo {
    position: absolute;
    top: 0;
    left: getSpacing(1);
    width: calc(100% - 16px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    padding-top: getSpacing(5);
    padding-bottom: getSpacing(5);
  }

  .container {
    @include grid--lg-v2;
  }

  .wrapper {
    grid-column: 2 / -2;
  }
}
