@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  @include grid--xs;
  overflow: hidden;
  padding-top: getSpacing(5);
}

.headingContainer,
.content,
.button,
.imageContainer {
  position: relative;
  grid-column: 1 / -1;
}

.paragraph,
.button,
.imageContainer {
  margin-top: getSpacing(4);
}

.heading {
  position: relative;
  @include text-heading-heading-xl();

  & b {
    font-weight: normal;
    color: var(--color-text-acquisition-alt);
  }
}

.paragraph {
  @include text-body-body-m-regular();
}

.imageContainer {
  padding-top: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: circle();
  transform: translateX(25%);
  object-fit: cover;
}

.line3D {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(60%, -20%);
}

.sphere3D {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .section {
    @include grid--md;
    padding-top: getSpacing(8);
  }

  .paragraph {
    @include text-body-body-l-regular();
  }

  .imageContainer {
    grid-column: 3 / -1;
    margin-top: -1 * getSpacing(6);
  }

  .image {
    transform: translateX(15%);
  }

  .line3D {
    transform: translate(50%, -40%);
  }

  .sphere3D {
    transform: translate(-15%, -50%);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    @include grid--lg;
    padding-top: getSpacing(14) + $page__nav-height--lg;
    min-height: 100vh;
  }

  .headingContainer {
    grid-column: 1 / span 9;
  }

  .heading {
    @include text-heading-display();
  }

  .content {
    grid-column: 1 / span 7;
    grid-row: 2;
  }

  .imageContainer {
    grid-column: 8 /-1;
    grid-row: 2;
    margin-top: getSpacing(4);
  }

  .image {
    transform: none;
  }

  .line3D {
    transform: translate(100%, -15%);
  }

  .sphere3D {
    transform: translate(-60%, -50%);
  }
}

$max-height: px2rem(1600);

@media (min-height: $max-height) {
  .section {
    height: 100vh;
    min-height: unset;
    max-height: $max-height;
  }
}
