@use '@/styles/utils' as *;

.label {
  @include text-misc-button;
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
}
