@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  overflow: hidden;
  padding-top: getSpacing(5);
  padding-bottom: getSpacing(5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  @include grid--xs-v2;
}

.picture,
.content,
.contenttitle {
  position: relative;
  grid-column: 1 / -1;
}

.contenttitle {
  display: none;
}

.title {
  text-align: center;
  @include text-heading-heading-s;
}

.content {
  display: flex;
  flex-direction: column;
  gap: getSpacing(2) 0;
}

.heading,
.headingPrimary {
  @include text-heading-heading-m;
  text-align: center;
  margin-bottom: getSpacing(4);
  color: var(--text-color-white);

  & b {
    font-weight: normal;
  }
}

.roller {
  position: relative;
  z-index: -1;
}

.placeholder {
  display: inline-block;
  visibility: hidden;
}

.item {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.headingPrimary b,
.headingPrimary b .roller,
.headingPrimary b .item {
  color: var(--color-text-highlight-alt);
  background: var(--text-text-highlight-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

.ailiceDarkblue .headingPrimary {
  color: white;
}

.paragraph {
  @include text-body-body-s-regular;
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: getSpacing(1);
}

.picture {
  padding-top: 100%;
  margin-top: getSpacing(6);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.hasBg {
  .picture {
    border-radius: 16px;
    background-color: var(--color-text-primary);
  }

  .shape {
    display: none;
  }
}

.ailice {
  .shape {
    display: none;
  }

  .rectAilice {
    display: none;
    position: absolute;
    top: 55%;
    height: 514px;
    background: linear-gradient(256deg, #6BC8EE -0.2%, #D9D3FF 81.89%);
    left: 60%;
    width: 50%;
    border-top-left-radius: 257px;
    border-bottom-left-radius: 257px;
  }

  &.isFlipped .rectAilice {
    left: -10%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 257px;
    border-bottom-right-radius: 257px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getSpacing(4);

    li {
      position: relative;
      padding-left: 36px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        background-image: url('/assets/list-dot.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.ailiceDarkblue {
  padding-top: getSpacing(6);
  padding-bottom: getSpacing(6);
  background-color: var(--color-text-primary);

  .shape {
    display: none;
  }

  .rectAiliceDarkblue {
    display: none;
    position: absolute;
    top: 40%;
    height: 514px;
    background: linear-gradient(294deg, #9479F7 0%, #8ADCFE 100%);
    left: 60%;
    width: 50%;
    border-top-left-radius: 257px;
    border-bottom-left-radius: 257px;
  }

  &.isFlipped .rectAiliceDarkblue {
    left: -10%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 257px;
    border-bottom-right-radius: 257px;
  }

  .paragraph {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 325;
    line-height: 120%; /* 21.6px */
    letter-spacing: 1.08px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getSpacing(4);

    li {
      position: relative;
      padding-left: 36px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        background-image: url('/assets/list-dot.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.hasNoBg {
  .shape {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1 * $page__padding--xs;
    width: auto;
    height: calc(100% + #{getSpacing(4)});
  }

  .shape,
  .picture::after {
    color: var(--color-background-primary-lightest);
  }

  &.isFlipped .shape {
    right: unset;
    left: -1 * $page__padding--xs;
    transform: translateY(-50%) scaleX(-1);
  }
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md-v2;
  }

  .hasNoBg {
    .shape {
      right: -1 * $page__padding--md;
      width: calc(100% - #{$page__padding--md});
    }

    &.isFlipped .shape {
      left: -1 * $page__padding--md;
    }
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    gap: getSpacing(7);
  }

  .container {
    @include grid--lg-v2;
    grid-auto-flow: dense;
  }

  .content {
    grid-column: 1 / span 5;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .contenttitle {
    display: flex;
    justify-content: center;
  }

  .heading,
  .paragraph {
    text-align: left;
  }

  .button {
    justify-content: flex-start;
  }

  .picture {
    grid-column: 7 / span 6;
    @include grid--center;
    margin-top: 0;
  }

  .isFlipped {
    .content {
      grid-column: 8 / span 5;
    }

    .picture {
      grid-column: 1 / span 6;
    }
  }

  .hasNoBg {
    .shape {
      right: -1 * $page__padding--lg;
      height: calc(100% + #{getSpacing(12)} * 2);
      top: -1 * getSpacing(12);
      transform: none;
    }

    .picture {
      &::after {
        content: '';
        position: absolute;
        top: -1 * getSpacing(12);
        left: 100%;
        width: 100vw;
        height: calc(100% + 2 * #{getSpacing(12)});
        background: currentColor;
      }
    }

    &.isFlipped {
      .shape {
        left: -1 * ($page__padding--lg + getSpacing(8));
        transform: scaleX(-1);
        width: 100%;
      }

      .picture::after {
        left: unset;
        right: 100%;
      }
    }
  }

  .ailice {
    .rectAilice {
      display: block;
    }

    ul {
      align-items: flex-start;
    }
  }

  .ailiceDarkblue {
    .rectAiliceDarkblue {
      display: block;
    }

    .content {
      grid-column: 1 / span 6;
    }

    ul {
      align-items: flex-start;
    }

    &.isFlipped .content {
      grid-column: 7 / span 6;
    }
  }
}
