@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  XS
// ------------------------------------------------------------

.marquee {
  position: relative;
  overflow: hidden;
}

.items {
  position: relative;
  display: flex;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  transform: translateX(-100%);
}
