@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  display: none;
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .section {
    display: block;
    background-color: var(--color-background-primary-darkest);
    color: var(--color-text-white);

    &.isLight {
      background-color: var(--color-background-primary-lightest);
      color: var(--color-text-primary);
    }
  }

  .container {
    position: relative;
    display: block;
    @include grid--lg;
  }

  .dotsWrapper {
    position: absolute;
    top: 0;
    left: getSpacing(4);
    height: 100%;
  }

  .dots {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .slide {
    grid-column: 1 / span 5;
    align-self: center;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: center;
    padding: 0;
  }

  .images {
    position: sticky;
    top: 0;
    grid-column: 6 / -1;
    grid-row: 1;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 0;
  }
}
