@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.card:hover {
  cursor: pointer;
}

.background {
  position: relative;
  border-radius: getRadius('regular');
  padding-top: 60%;
  background: var(--color-background-primary-white);
  box-shadow: 8px 16px 32px rgba(136, 141, 188, 0.16);
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  object-fit: contain;

  .isLarge & {
    width: 65%;
    height: 65%;
  }
}

.header {
  margin-top: getSpacing(2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  @include text-body-body-s-bold;
  flex: 1 1 auto;
}

.icon {
  flex: 0 0 auto;
  align-self: flex-start;
  margin-left: getSpacing(2);
  border-radius: 50%;
  background: var(--color-background-primary-light);
  padding: getSpacing(1);
}

.description {
  @include text-body-body-xs-regular;
  margin-top: getSpacing(1);
}

.callToAction {
  margin-top: getSpacing(1.5);
  justify-content: flex-start;
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------
