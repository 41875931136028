@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.shadow {
  position: absolute;
  bottom: getSpacing(-4);
  left: 4%;
  width: 92%;
  height: 40%;
  background: var(--gradient-system-purple-blue-r);
  filter: blur(20px);
  border-radius: getRadius('regular');
  transition: all 0.3s ease-out;
  will-change: transform;
  opacity: 0.7;
  pointer-events: none;
}
