@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.section {
  position: relative;
  background-color: var(--color-background-highlight-dark);
  color: var(--color-text-white);
  text-align: center;
  overflow: hidden;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.circle {
  height: 100%;
  width: 100%;
  margin: 0 auto;

  & circle {
    fill: transparent;
    stroke: var(--color-border-primary-darkest);
    stroke-width: 0.1px;
    stroke-dasharray: 0.25;
  }
}

.container {
  position: relative;
  @include grid--xs;
  padding-top: getSpacing(16);
  padding-bottom: getSpacing(16);
}

.heading {
  @include text-heading-heading-m;
}

.heading,
.paragraph {
  grid-column: 1 / -1;
}

.paragraph {
  margin-top: getSpacing(2);
}

.button {
  grid-column: 2 / -2;
  margin-left: -1 * $grid__gap--xs;
  margin-right: -1 * $grid__gap--xs;
  width: calc(100% + 2 * $grid__gap--xs);
  margin-top: getSpacing(9);
}

// ------------------------------------------------------------
//  TABLET BREAKPOINT
// ------------------------------------------------------------

@include media('md') {
  .container {
    @include grid--md;
    padding-top: getSpacing(16);
    padding-bottom: getSpacing(16);
  }

  .heading,
  .paragraph {
    grid-column: 2 / -2;
  }

  .button {
    grid-column: 4 / -4;
    margin-top: getSpacing(6);
  }
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .container {
    @include grid--lg;
    padding-top: getSpacing(22);
    padding-bottom: getSpacing(22);
  }

  .heading,
  .paragraph,
  .button {
    grid-column: 3 / -3;
  }
}
