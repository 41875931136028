@use '@/styles/utils' as *;

// ------------------------------------------------------------
//  MOBILE FIRST BREAKPOINT
// ------------------------------------------------------------

.navigation {
  display: none;
}

// ------------------------------------------------------------
//  DESKTOP BREAKPOINT
// ------------------------------------------------------------

@include media('lg') {
  .wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: $page__max-width;
    transform: translateX(-50%);
    padding: 0 getSpacing(3);
    z-index: getZIndex('navigation');

    @media (min-width: px2rem(1600)) {
      padding: 0 $page__padding--lg;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    padding: getSpacing(3) 0;
  }

  .logo {
    display: block;
    margin-right: getSpacing(8);
  }

  .items {
    margin-left: auto;
    margin-right: getSpacing(1);
    align-self: stretch;
    display: flex;
    // Make items visually center, hack font baseline
    padding-top: 3px;
  }

  .category {
    display: flex;
    align-items: stretch;
  }

  .categorySplit {
    position: relative;
    padding: 0 getSpacing(1);
    margin-left: getSpacing(3);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-45%);
      left: 50%;
      width: 1px;
      height: getSpacing(2);
      background: var(--color-background-primary-darkest);
    }
  }

  .item {
    @include text-misc-button();
    color: var(--color-navy-900);
    padding: 0 getSpacing(2);
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .isLight {
    .item {
      color: var(--color-text-white);
    }

    .category::after {
      background: var(--color-background-primary-white);
    }
  }

  .divider {
    margin: 0 getSpacing(3);
    padding: 0;
    width: 1px;
    height: getSpacing(2);
    background-color: var(--color-border-primary-darkest);
    border: none;
  }

  .transitionEnter {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
  }

  .transitionEnterFrom {
    // transform: translateY(0.25rem);
    opacity: 0;
  }

  .transitionEnterTo {
    // transform: translateY(0);
    opacity: 1;
  }

  .transitionLeave {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    // transition-duration: 150ms;
    transition-duration: 100ms;
    // transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-timing-function: ease-out;
  }

  .transitionLeaveFrom {
    // transform: translateY(0);
    opacity: 1;
  }

  .transitionLeaveTo {
    // transform: translateY(0.25rem);
    opacity: 0;
  }

  .popover {
    display: flex;
    align-items: center;

    .item {
      position: relative;
      cursor: default;
      z-index: 20;

      &:hover {
        cursor: default;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        top: calc(100% + 13px);
        left: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top: 12px solid var(--color-border-primary);
        border-right: 12px solid var(--color-border-primary);
        opacity: 0;
        transform: translateX(-50%) rotate(-45deg);
        transition-timing-function: ease-out;
        border-radius: 0 4px 0 0;
        transition-duration: 50ms;
      }
    }

    &.isOpen .item {
      color: var(--color-text-white);
      background: var(--color-border-primary);
      border-radius: getRadius('small');

      &::after {
        transition-timing-function: ease-in;
        transition-delay: 50ms;
        transition-duration: 150ms;
        opacity: 1;
      }
    }

    .panelWrapper {
      max-width: $page__max-width;
      position: absolute;
      z-index: 10;
      top: 100%;
      left: getSpacing(3);
      width: calc(100vw - #{getSpacing(6)});
      margin-top: getSpacing(-3);
      padding-top: getSpacing(3);
    }

    .panel {
      background: var(--color-background-primary-white);
      box-shadow: 0 24px 80px rgba(34, 31, 97, 0.2);
      padding: getSpacing(5) getSpacing(11);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: getSpacing(4);
      grid-auto-flow: column dense;
      justify-content: center;
      margin: auto;
      border-radius: getRadius('small');
      border: 2px solid var(--color-border-primary);
    }

    .heading {
      @include text-misc-button();
      color: var(--color-navy-900);
      display: flex;
      gap: getSpacing(1);
      align-items: center;
    }

    .icon {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      justify-items: center;
      align-items: center;
      width: getSpacing(4);
      height: getSpacing(4);
      border-radius: 4px;
      background: var(--color-background-primary-lightest);
      padding: getSpacing(1);

      &.isLarge {
        width: getSpacing(8);
        height: getSpacing(8);
      }
    }

    .column {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: getSpacing(4);

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: getSpacing(-2);
        width: 1px;
        height: 60%;
        transform: translateY(-50%);
        background: var(--color-border-primary);
      }

      &:nth-child(2) {
        padding: 0 getSpacing(2);
      }

      &:nth-child(3) {
        padding-left: getSpacing(4);
      }
    }

    .list,
    .cards {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: getSpacing(1.5);

      &.isLarge {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: getSpacing(4);
      }
    }

    .link {
      @include text-body-body-s-regular;
      border-radius: getRadius('x-small');
      display: flex;
      align-items: center;
      gap: getSpacing(2);

      &:hover {
        @include text-body-body-s-bold;

        .icon {
          background: var(--color-background-primary-light);
        }
      }
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: getSpacing(1.5);
    }

    .card {
      position: relative;
      display: block;
      border-radius: getRadius('x-small');
      background: linear-gradient(180deg, rgba(29, 26, 85, 0.1) 0%, rgba(29, 26, 85, 0.8) 100%);
      overflow: hidden;
    }

    .picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: getRadius('x-small');
      transition-duration: 200ms;
      transition-timing-function: ease-out;
    }

    .card:hover .picture {
      transform: scale(1.1);
    }

    .label {
      @include text-heading-heading-xs();
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: getSpacing(1.5) getSpacing(4);
      color: var(--color-white);
      border-radius: getRadius('x-small');
      background: linear-gradient(180deg, rgba(29, 26, 85, 0.1) 0%, rgba(29, 26, 85, 0.8) 100%);
      overflow: hidden;
    }

    &.marketplace {
      .panel {
        border-color: var(--color-background-marketplace);
      }

      .border {
        background: var(--color-background-marketplace);
      }

      &.isOpen .item {
        background: var(--color-background-marketplace);
      }

      .item::after {
        border-top-color: var(--color-background-marketplace);
        border-right-color: var(--color-background-marketplace);
      }
    }

    &.acquisition {
      .panel {
        border-color: var(--color-background-acquisition);
      }

      .border {
        background: var(--color-background-acquisition);
      }

      &.isOpen .item {
        background: var(--color-background-acquisition);
      }

      .item::after {
        border-top-color: var(--color-background-acquisition);
        border-right-color: var(--color-background-acquisition);
      }
    }
  }
}
